import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import swoosh from "../../Assets/Logo/swoosh.png";

import "./Location.css";
import { getLocationOnLoad, getLocations } from "../../Helpers/API/formsAPI";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";
import pin from "../../Assets/Location/house.png";
import { toast, ToastContainer } from "react-toastify";
import {
  formatDate,
  getTime,
  getTimeDetails,
  getUserType,
  refreshPage,
} from "../../Helpers/Utils/Common";
import {
  createTimesheet,
  createTimesheetOut,
  searchFormResponses,
  searchTimesheet,
} from "../../Helpers/API/locationAPI";
import { validateTimesheet } from "../../Helpers/Validation/TimesheetValidation";
import InputError from "../../Components/InputError/InputError";
import entrasol from "../../Assets/Logo/entrasol.png";
import diabetasol from "../../Assets/Logo/diabetasol.png";
import logo from "../../Assets/Logo/kalbe.png";
import Compressor from "compressorjs";

function Location() {
  var google = window.google;
  const navigate = useNavigate();
  const {state} = useLocation();
  console.log(state)
  const [storesClinics, setStoresClinics] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 0,
      lng: 0,
    },
  });
  const [ready, setReady] = useState(false);
  const [showExceed, setShowExceed] = useState(false);
  const [stores, setStores] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [chosenStore, setChosenStore] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openMerchantModal, setOpenMerchantModal] = useState(false);
  const [merchantNames, setMerchantNames] = useState([]);
  const [openTimeout, setOpenTimeout] = useState(false);
  const [targetQ, setTargetQ] = useState(0);
  const [reachedQ, setReachedQ] = useState(0);
  const [logDetails, setLogDetails] = useState({
    type: state ? state?.type : "",
    time: "",
    //to be integrated
    address_1: "",
    address_2: "",
    // -------
    address: "",
    longitude: "",
    latitude: "",
    photo_proof: "",
  });
  const [timesheedID, setTimeSheetID] = useState("");
  const [timesheetDetails, setTimeSheetDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ photo_proof: false });
  const [time, setTime] = useState(getTime(new Date()));
  const [showStoresToday, setShowStoresToday] = useState(false);
  const [regStores, setRegStores] = useState([]);
  const headers = ["Merchant Names"]

  const StoreMarker = ({ data, details }) => (
    <div
      className="store-marker"
      onClick={() => {
        setChosenStore(data);
        setOpenStore(true);
      }}
    >
      {details}
    </div>
  );

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    var copy = { ...defaultProps };
    copy["center"]["lat"] = position.coords.latitude;
    copy["center"]["lng"] = position.coords.longitude;
    setDefaultProps(copy);
    setCurrentPosition(copy);

    logDetails["latitude"] = position.coords.latitude;
    logDetails["longitude"] = position.coords.longitude;
    // fetchLocation();
    fetchTimesheetDetails();
  }

  // async function fetchLocation() {
  //   const response = await getLocationOnLoad(
  //     logDetails["latitude"],
  //     logDetails["longitude"]
  //   );
  //   if (response.data) {
  //     localStorage.setItem(
  //       "street_address",
  //       JSON.stringify(response.data.results[0].formatted_address)
  //     );
  //     localStorage.setItem("latitude1", JSON.stringify(logDetails["latitude"]));
  //     localStorage.setItem("latitude2", JSON.stringify(logDetails["latitude"]));
  //     localStorage.setItem(
  //       "longitude1",
  //       JSON.stringify(logDetails["longitude"])
  //     );
  //     localStorage.setItem(
  //       "longitude2",
  //       JSON.stringify(logDetails["longitude"])
  //     );
  //     localStorage.setItem(
  //       "address1",
  //       JSON.stringify(response.data.results[0].formatted_address)
  //     );
  //     localStorage.setItem(
  //       "address2",
  //       JSON.stringify(response.data.results[0].formatted_address)
  //     );
  //     logDetails["address"] = response.data.results[0].formatted_address;
  //     logDetails["address_1"] = response.data.results[0].formatted_address;
  //     logDetails["address_2"] = response.data.results[0].formatted_address;
  //   }
  // }

  async function fetchTimesheetDetails() {
    const response = await searchTimesheet();
    if (response.data) {
      var details = response.data.data[0];
      logDetails["target_quota"] = details.target_quota ? details.target_quota : "0";
      logDetails["reached_quota"] = details.reached_quota;


      setTimeSheetID(details["id"]);

      if (
        details["time_in"] !== undefined &&
        details["time_in"] !== "" &&
        details["time_in"] !== null &&
        details["time_out"] !== undefined &&
        details["time_out"] !== "" &&
        details["time_out"] !== null
      ) {
        logDetails["type"] = "TIME-IN";
        timesheetDetails["time-in"] = getTime(new Date(details["time_in"]));
        timesheetDetails["time-out"] = getTime(new Date(details["time_out"]));
      }
      if (
        (details["time_in"] !== undefined ||
          details["time_in"] !== "" ||
          details["time_in"] !== null) &&
        (details["time_out"] === undefined ||
          details["time_out"] === "" ||
          details["time_out"] === null)
      ) {
        logDetails["type"] = "TIME-OUT";
        timesheetDetails["time-in"] = getTime(new Date(details["time_in"]));
      }
      if (
        details["time_in"] === undefined ||
        details["time_in"] === "" ||
        (details["time_in"] === null &&
          (details["time_out"] === undefined ||
            details["time_out"] === "" ||
            details["time_out"] === null))
      ) {
        logDetails["type"] = "TIME-IN";
        // timesheetDetails["time-in"] = getTime(new Date(details["time_in"]))
        // timesheetDetails["time-out"] = getTime(new Date(details["time_out"]))
      }

      setReady(true);
    }
    if (response.error) {
      logDetails["type"] = "TIME-IN";
      setReady(true);
    }
  }

  async function fetchForms() {
    const response = await getLocations(
      "",
      "",
      logDetails.latitude,
      logDetails.longitude,
      ""
    );
    if (response.data) {
      setStoresClinics(response.data);
    }
  }
  async function fetchFormsWParams(lat, long) {
    const response = await getLocations("", "", lat, long, "");

    if (response.data) {
      setStoresClinics(response.data);
    }
  }

  async function createTimeIn() {
    const response = await createTimesheet(logDetails);
    if (response.data) {
      logDetails["type"] = "TIME-OUT";
      toast.success("Time-in Successfull", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
      setTimeSheetID(response.data.timesheet_id);
      setOpenModal(false);
      setIsLoading(false);
      fetchTimesheetDetails();
      localStorage.setItem("time", JSON.stringify("OUT"));
    } else {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  }

  async function createTimeOut() {
    const response = await createTimesheetOut(timesheedID);
    if (response.data) {
      logDetails["type"] = "COMPLETE";
      toast.success("Time-out Successfull", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
      setOpenTimeout(false);
      setIsLoading(false);
      fetchTimesheetDetails();
      localStorage.setItem("time", JSON.stringify("COMPLETE"));
      // refreshPage();
    } else {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  }

  function handleMarkerClick(data) {
    setChosenStore(data);
    setOpenStore(true);
    var copy = { ...defaultProps };
    copy["center"]["lat"] = data.latitude;
    copy["center"]["lng"] = data.longitude;
    setDefaultProps(copy);
  }

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => {
      return (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={props.currentPosition}
          onClick={props.onDragEnd}
        >
          <Marker
            position={props.center}
            draggable={true}
            onDragEnd={props.onDragEnd}
          />

          {props.stores.map((data, index) => (
            <Marker
              label={{
                text: data.store_venue,
                color: "black",
                fontSize: "14px",
                className: "marker-label-pos",
              }}
              title={data.store_venue}
              position={{
                lat: parseFloat(data.long_lat_2.split("/")[1]),
                lng: parseFloat(data.long_lat_2.split("/")[0]),
              }}
              key={index}
              icon={
                new window.google.maps.MarkerImage(
                  pin,
                  null /* size is determined at runtime */,
                  null /* origin is 0,0 */,
                  null /* anchor is bottom center of the scaled image */,
                  new window.google.maps.Size(32, 32)
                )
              }
              onClick={(coords) => {
                handleMarkerClick(data);
                onMarkerDragEnd(coords);
              }}
            />
          ))}
        </GoogleMap>
      );
    })
  );

  function handleConfirmLocation() {
    navigate("/register/add")
    // navigate(
    //   "/register/add/" +
    //     currentPosition.center["lat"] +
    //     "/" +
    //     currentPosition.center["lng"]
    // );
  }


  // THIS COMPRESSES FILE AND CONVERTS IT INTO BASE 64 
  // const handleCompressUpload = (e) => {
  //   setFiles([])
  //   setFileNames([])
  //   setDetails({ ...details, claim_img: null });  
  //   // console.log("TARGET",e.target.files)
  //   const images = e.target.files
  //   var count = 0
  //   Array.from(images).forEach(file => {
  //     count++
  //     // console.log("before",file.size);
  //   })
  //   if(count <= 3) {
  //     var array = [];
  //     var filenames = [];
  //     Array.from(images).forEach(file => { 
  //       console.log("file",file);
        
  //       if (file.size > 4000000) {
  //         setShowExceed(true);
  //         setFiles([]);
  //       } else {
  //         //COMPRESSING FILE
  //         new Compressor(file, {
  //           quality: 0.6,
  //           success: (compressedResult) => {
  //               setCompressedFile(compressedResult);
  //               //CONVERTING TO BASE64
  //               var fileReader = new FileReader();
  //               var base64;
  //               fileReader.onload = function(fileLoadedEvent){
  //                 base64 = fileLoadedEvent.target.result;
  //                 array.push(base64);
  //                 setFiles(array);
  //                 setDetails({ ...details, claim_img: array });  
  //               }
  //               fileReader.readAsDataURL(compressedResult)
  //               filenames.push(file.name);
  //               setFileNames(filenames)
  //           },
  //         });
  //       }
  //     });
  //   } else {
  //     setShowMaximum(true);
  //     setFiles([]);
  //   }
  // };


  const handleUpload = (e) => {
    const image = e.target.files[0]
    console.log(image)
    // COMPRESSION
    new Compressor(image, {
      quality: 0.6,
      success: (compressedResult) => {
          //CONVERTING TO BASE64
          var fileReader = new FileReader();
          var base64;
          fileReader.onload = function(fileLoadedEvent){
            base64 = fileLoadedEvent.target.result;
            setLogDetails({
              ...logDetails,
              photo_proof: base64,
            })
          }
          fileReader.readAsDataURL(compressedResult)
      },
    });
    // if (image.size > 2000000) {
    //   setShowExceed(true);
    // } else {
    // }
  };

  function onMarkerDragEnd(coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    var copy = { ...defaultProps };
    copy["center"]["lat"] = lat;
    copy["center"]["lng"] = lng;
    localStorage.setItem("latitude2", JSON.stringify(lat));
    localStorage.setItem("longitude2", JSON.stringify(lng));
    setDefaultProps(copy);
    geocodePosition(latLng);
    fetchFormsWParams(lat, lng);
  }

  function geocodePosition(pos) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        latLng: pos,
      },
      function (responses) {
        if (responses && responses.length > 0) {
          localStorage.setItem(
            "address2",
            JSON.stringify(responses[0].formatted_address)
          );
          logDetails["address_2"] = responses[0].formatted_address;
        } else {
          toast.error("Cannot determine address at this location.", {
            autoClose: 4000,
            hideProgressBar: true,
            theme: "colored",
          });
        }
      }
    );
  }

  
  function handleLogChange(e) {
    const { name, value } = e.target;
    setLogDetails({
        ...logDetails,
        [name]: value,
    })
	}

  function handleTimeOut() {
    setOpenTimeout(true);
  }

  function handleTimeIn() {
    setOpenModal(true);
  }

  async function handleViewMerchant() {
    const response = await searchFormResponses();
    // console.log(response)
    if(response.data) {
      var allData = response.data.data
      var names = []
      allData.map((data) => {
        names.push(data.merchant)
      })
      setMerchantNames(names.reverse())
      // setOpenMerchantModal(true);
    } else {
      toast.error("No merchant registered yet.", {
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  }
  // console.log(merchantNames)

  function handleLogStaff() {
    if (validateTimesheet(logDetails, setIsError)) {
      setIsLoading(true);
      createTimeIn();
    }
  }

  function handleLogOut() {
    logDetails["type"] = "TIME-IN";
    setIsLoading(true);
    createTimeOut();
  }

  function updateTime() {
    setTime(getTime(new Date()));
  }

  // useEffect(() => {
  //   getLocation();
  // }, []);

  // useEffect(() => {
  //   if (logDetails.latitude !== "") {
  //     fetchForms();
  //   }
  // }, [logDetails]);

  useEffect(() => {
    // refreshPage()
    // setInterval(updateTime, 1000);
    // if(!state) {
    //   fetchTimesheetDetails()
    // }
      fetchTimesheetDetails()
      handleViewMerchant()
  }, []);
  // console.log(logDetails)

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-md-center p-5 pt-5 pb-0">
        <Col md={12} className="pb-2 mt-5">
            <Row className="row-detail ">
              <Col xs={12} sm={7} className="details time pt-1">
                <span className="font-big pt-1 mt-1">{time}</span>
                <br />
                <span>{formatDate(new Date())}</span>
              </Col>
              {/* {logDetails.type === "TIME-OUT" && (
                <Col
                  xs={5}
                  sm={4}
                  className="details log p-1"
                  onClick={handleTimeOut}
                >
                  <Row>
                    <Col xs={12} className="pb-0">
                      TIME
                    </Col>
                    <Col xs={12} className="font-big pt-0">
                      OUT
                    </Col>
                  </Row>
                </Col>
              )}
              {logDetails.type === "TIME-IN" && (
                <Col
                  xs={5}
                  sm={4}
                  className="details log p-1"
                  onClick={handleTimeIn}
                >
                  <Row>
                    <Col xs={12} className="pb-0">
                      TIME
                    </Col>
                    <Col xs={12} className="font-big pt-0">
                      IN
                    </Col>
                  </Row>
                </Col>
              )}
              {logDetails.type === "COMPLETE" && (
                <Col xs={5} sm={4} className="details log p-1">
                  <Row>
                    <Col xs={12} className="pb-0 font-small">
                      TIME-IN
                    </Col>
                    <Col xs={12} className="pt-0">
                      {timesheetDetails["time-in"]}
                    </Col>
                    <Col xs={12} className="pb-0 font-small">
                      TIME-OUT
                    </Col>
                    <Col xs={12} className="pt-0">
                      {timesheetDetails["time-out"]}
                    </Col>
                  </Row>
                </Col>
              )} */}
            </Row>
        </Col>
        <Col md={12} className="pb-2 mt-2">
            <Row className="row-detail">
              <Col xs={12} sm={7} className="subdetails time pt-1">
                <span className="font-big pt-1 mt-1">TARGET QUOTA: {logDetails.target_quota}</span>
              </Col>
            </Row>
        </Col>
        <Col md={12} className="pb-2 mt-2">
            <Row className="row-detail">
              <Col xs={12} sm={7} className="subdetails time pt-1">
                <span className="font-big pt-1 mt-1">REACHED QUOTA: {logDetails.reached_quota}</span>
              </Col>
            </Row>
        </Col>
        <div className="table-container mt-2">
          <div className="table-header">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Registered Merchant Name</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="table-body">
            <table className="table">
              <tbody>
                {
                  merchantNames.length > 0 ? (
                    <>
                    {
                      merchantNames.map((data, index) => (
                        <tr key={index}>
                          <td className="text-start">•</td>
                          <td className="text-start">{data}</td>
                        </tr>
                      ))
                    }
                    </>
                  ) : (
                    <div className="font-big pt-5 mt-5">
                      <span className="font-big pt-5 mt-5">NO DATA FOUND</span>
                    </div>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
        {/* <Form.Group className="mt-2 mb-3" controlId="formGroupSubmit">
          <Button
            variant="primary"
            size="md"
            className="full-width-btn view-btn"
            onClick={handleViewMerchant}
            // onClick={handleLogStaff}
          >
            View Registered Merchants
          </Button>
        </Form.Group> */}
        <Col md={12} className="pb-2">
          <Row className="row-detail">
            <Form>
              <Form.Group className="mt-5 mb-3" controlId="formGroupSubmit">
                {
                  (logDetails.type === "TIME-IN" || logDetails.type === "COMPLETE") && (
                  // (getTimeDetails() === "IN" || getTimeDetails() === "COMPLETE") && (
                    isLoading ? (
                      <Row className="justify-content-md-center">
                        <Col md={1} sm={1} xs={1} className="left-38">
                          <ReactLoading
                            type="spinningBubbles"
                            color="var(--secondary-color)"
                            height={100}
                            width={50}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Button
                        variant="primary"
                        size="md"
                        className="full-width-btn confirm-btn"
                        onClick={handleTimeIn}
                        // onClick={handleLogStaff}
                      >
                        Time In
                      </Button>
                    )
                  )
                }
                {
                  (logDetails.type === "TIME-OUT") && (
                    isLoading ? (
                      <Row className="justify-content-md-center">
                        <Col md={1} sm={1} xs={1} className="left-38">
                          <ReactLoading
                            type="spinningBubbles"
                            color="var(--secondary-color)"
                            height={100}
                            width={50}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Button
                        variant="primary"
                        size="md"
                        className="full-width-btn confirm-btn mt-3"
                        onClick={handleTimeOut}
                      >
                        Time Out
                      </Button>
                    )
                  )
                }
              </Form.Group>
            </Form>
          </Row>
        </Col> 
        {getUserType().toLowerCase() !== "supervisor" && (
          <Col md={12}>
            <Button
              variant="primary"
              size="md"
              className="full-width-btn confirm-btn"
              onClick={handleConfirmLocation}
              disabled={logDetails.type !== "TIME-OUT"}
            >
              Form
            </Button>
          </Col>
        )}
      </Row>
      {/* <img src={swoosh} alt="logo" height={250} className={"swoosh"} /> */}
      <Modal
        show={openStore}
        onHide={() => setOpenStore(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Row>
            <Modal.Title>
              {chosenStore.location_name}
              <br />
            </Modal.Title>
            <span className="subtitle med">
              {chosenStore.customer_name?.toUpperCase()}
            </span>
            {/* <span><em>Remarks: {chosenStore.remarks}</em></span> */}
          </Row>
        </Modal.Header>
        <Modal.Body>
          <img
            src={chosenStore.photo}
            alt={chosenStore.location_name}
            className="img-modal"
            width={300}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={openTimeout}
        onHide={() => setOpenTimeout(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Form.Label className="title mt-2">
            CONFIRM {logDetails.type.toUpperCase()}?
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Form>
              <Form.Group className="mb-3" controlId="formGroupSubmit">
                {isLoading ? (
                  <Row className="justify-content-md-center">
                    <Col md={1} sm={1} xs={1} className="left-38">
                      <ReactLoading
                        type="spinningBubbles"
                        color="var(--secondary-color)"
                        height={100}
                        width={50}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Button
                    variant="primary"
                    size="md"
                    className="full-width-btn confirm-btn logout"
                    onClick={handleLogOut}
                  >
                    Time Out
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setLogDetails({ ...logDetails, photo_proof: "" });
        }}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Form.Label className="title mt-2">
            {logDetails.type.toUpperCase()}
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Form>
              {/* <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">Address 1</Form.Label>
                <textarea
                  type="text"
                  name="reason"
                  className="form-control"
                  id="address"
                  aria-describedby="address"
                  value={logDetails.address_1}
                  disabled
                ></textarea>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">Address 2</Form.Label>
                <textarea
                  type="text"
                  name="reason"
                  className="form-control"
                  id="address"
                  aria-describedby="address"
                  value={logDetails.address_2}
                  disabled
                ></textarea>
              </Form.Group> */}
              <Form.Group className="mb-3">
                <Form.Label className="font-med black">
                  Proof of Attendance<span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  size="sm"
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  name="store_image"
                  // multiple
                  onChange={(e) =>
                    handleUpload(e)
                    // setLogDetails({
                    //   ...logDetails,
                    //   photo_proof: e.target.files,
                    // })
                  }
                />
                <InputError
                  isValid={isError.photo_proof}
                  message={"This field is required*"}
                />
              </Form.Group>
              <Form.Group className="mt-5 mb-3" controlId="formGroupSubmit">
                <Button
                  variant="primary"
                  size="md"
                  className="full-width-btn danger-btn  mb-2"
                  onClick={() => {
                    setOpenModal(false);
                    setLogDetails({ ...logDetails, photo_proof: "" });
                  }}
                >
                  Cancel
                </Button>
                {isLoading ? (
                  <Row className="justify-content-md-center">
                    <Col md={1} sm={1} xs={1} className="left-38">
                      <ReactLoading
                        type="spinningBubbles"
                        color="var(--secondary-color)"
                        height={100}
                        width={50}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Button
                    variant="primary"
                    size="md"
                    className="full-width-btn confirm-btn"
                    onClick={handleLogStaff}
                  >
                    Time In
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showExceed}
        onHide={() => setShowExceed(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          {/* <Form.Label className="title mt-2">
            CONFIRM {logDetails.type.toUpperCase()}?
          </Form.Label> */}
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Row className="justify-content-md-center">
              <Form.Label className="font-med text-center black">
                Image should not be greater than 2MB!<span className="required-badge">*</span>
              </Form.Label>
            </Row>
            <Row className="justify-content-center">
              <Col xs={4}>
                <Button
                  variant="primary"
                  size="md"
                  className="full-width-btn confirm-btn logout"
                  onClick={() => refreshPage()}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

    </Container>
  );
}

export default Location;
