import { handleValidationChange } from "./CommonValidation";

export const validateTimesheet = (data, setIsError) => {

    var isValid = true;
    var isValidPhoto = true

    if(data.photo_proof === "") {
        handleValidationChange("photo_proof", true, setIsError);
        isValidPhoto = false;
    } else {
        handleValidationChange("photo_proof", false, setIsError);
        isValidPhoto = true;
    }

   

    isValid = isValid && isValidPhoto

    return isValid
  
}