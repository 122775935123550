import React, { useEffect, useState } from "react"
import { useStep } from "react-hooks-helper"
import Registration from "./Registration"
import Consumer from "./Consumer"
import { useParams } from "react-router-dom"
import { getTime, getTime12Hour } from "../../Helpers/Utils/Common"
import Summary from "./Summary"
import {
  getAccounts,
  getFreebies,
  getPromos,
  getDropdownData,
  getAllMerchants,
  getAllOutlets,
} from "../../Helpers/API/formsAPI"
import RegistrationVer2 from "./RegistrationVer2"
import SummaryVer2 from "./SummaryVer2"

const steps = [
  { id: "registration" },
  // { id: "consumer" },
  { id: "summary" },
]

function SwitchForm() {
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  })

  const [addCustomer, setAddCustomer] = useState(false)
  const [formId, setFormId] = useState("")
  const [selectedEscalationIssues, setSelectedEscalationIssues] = useState([])
  const [outlets, setOutlets] = useState([
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "choice 1",
    // 	value: "choice 1",
    // },
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "choice 2",
    // 	value: "choice 2",
    // },
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "choice 3",
    // 	value: "choice 3",
    // },
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "choice 4",
    // 	value: "choice 4",
    // },
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "choice 5",
    // 	value: "choice 5",
    // },
    // {
    // 	name: "outlet_id",
    //   var_name: "outlet",
    // 	label: "Others",
    // 	value: "Others",
    // },
  ])
  const [issues, setIssues] = useState([])
  const [primaryDetails, setPrimaryDetails] = useState({
    // classification_type: "",
    serial_numbers: [],
    store_codes: [],
    other_competitor: [],
    file_names: [],
    escalation_issues: "",
    available_terminal_other_details: "",
    solution_device_other_details: "",
    own_merchandising_other_details: "",
    merchant_concern_other_details: "",
    servicing_other_details: "",
    merchandising_request_other_details: "",
    merchant_mid_sqr: "",
    merchant_mid_terminal: "",
    sn_terminal: "",
    store_code: "",
    date: new Date().toISOString().split("T")[0],
    selected_outlet: [{ label: "", value: "" }],
  })
  const [details, setDetails] = useState({
    //CUSTOMER DETAILS
    name: "",
    contact_no: "",
    email: "",
    call_time: "",

    // call_time:getTime12Hour(new Date()),
    bumo: "", //FOR INSTORE/OFFSTORE
    patient_type: "", //FOR INCLINIC
    lc_nc_p: "",
    eu_cg: "",
    or_no: "",
    is_diabetasol: "0",
    is_entrasol: "0",
    is_diabetasol_sweetener: "0",
    diabetasol_diagnosis: "",
    entrasol_diagnosis: "",
    diabetasol_sweetener_diagnosis: "",
  })
  const [sqrTerminals, setSqrTerminals] = useState([
    { value: "Maya Only as solution", selected: false },
    { value: "Maya + One Competitor Solution", selected: false },
    { value: "Maya + 2 or More Competitor Solution", selected: false },
  ])
  const [merchandisingMaterials, setMerchandisingMaterials] = useState([
    {
      name: "MAYA",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "GCash",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "BDO",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "Metro Bank",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "BPI",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "Food Panda",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
    {
      name: "Other Bank/E-Wallet",
      door_hanger: false,
      qr_standee: false,
      terminal_standee: false,
      billfold: false,
      other_merch_mats: false,
    },
  ])

  const [checked, setChecked] = useState(false)
  const [checkedDiabetasol, setCheckedDiabetasol] = useState(false)
  const [checkedEntrasol, setCheckedEntrasol] = useState(false)
  const [checkedDiabetasolSweetener, setCheckedDiabetasolSweetener] =
    useState(false)

  const [promoList, setPromoList] = useState([])
  // const [promoList, setPromoList] = useState([
  //   { name: "DIABETASOL 180g LESS40PHP", qty: 0 },
  //   { name: "DIABETASOL 600g FREEQUAKEROATS", qty: 0 },
  //   { name: "DIABETASOL 600g LESS75PHP", qty: 0 },
  //   { name: "DIABETASOL 800g LESS150PHP", qty: 0 },

  //   { name: "ENTRASOL 200g LESS40PHP", qty: 0 },
  //   { name: "ENTRASOL 600g LESS100PHP", qty: 0 },
  // ]);

  const [promoListLKA, setPromoListLKA] = useState([
    { name: "ENTRASOL 180g LESS40PHP", qty: 0 },
    { name: "ENTRASOL 600g FREEQUAKEROATS", qty: 0 },
    { name: "DIABETASOL 600g LESS75PHP", qty: 0 },
    { name: "DIABETASOL 800g LESS150PHP", qty: 0 },

    { name: "DIABETASOL 200g LESS40PHP", qty: 0 },
    { name: "ENTRASOL 600g LESS100PHP", qty: 0 },
  ])

  const [freebies, setFreebies] = useState([])
  const [customers, setCustomers] = useState([])
  const [malls, setMalls] = useState([])
  // const [freebies, setFreebies] = useState([
  //   { name: "DIABETASOL", qty: 0 },
  //   { name: "DIABETASOL SWEETENER", qty: 0 },
  //   { name: "ENTRASOL", qty: 0 },
  // ]);
  const [productCodes, setProductCodes] = useState([
    { name: "DIABETASOL SWEET 50x1g", qty: 0 },

    { name: "DIABETASOL-VAN 180g", qty: 0 },
    { name: "DIABETASOL-VAN 360g", qty: 0 },
    { name: "DIABETASOL-VAN 600g", qty: 0 },
    { name: "DIABETASOL-VAN 800g", qty: 0 },
    { name: "DIABETASOL-VAN 1kg", qty: 0 },

    { name: "DIABETASOL-CHO 180g", qty: 0 },
    { name: "DIABETASOL-CHO 360g", qty: 0 },
    { name: "DIABETASOL-CHO 600g", qty: 0 },

    { name: "DIABETASOL-CAP 180g", qty: 0 },
    { name: "DIABETASOL-CAP 600g", qty: 0 },

    { name: "ENTRASOL-VAN 200g", qty: 0 },
    { name: "ENTRASOL-VAN 600g", qty: 0 },

    { name: "ENTRASOL-CHO 200g", qty: 0 },
    { name: "ENTRASOL-CHO 600g", qty: 0 },
  ])
  const [accounts, setAccounts] = useState([])

  async function fetchAccounts() {
    const response = await getAccounts()
    if (response.data) {
      setAccounts(response.data)
    }
  }

  async function fetchDropdownData() {
    const response = await getDropdownData()
    // console.log(response)
    if (response.data) {
      setCustomers(response.data.customers)
      setMalls(response.data.malls)
    }
  }

  async function fetchFreebies() {
    const response = await getFreebies()
    if (response.data) {
      var arr = []
      response.data.map((data) => {
        arr.push({ ...data, qty: 0 })
      })
      setFreebies(arr)
    }
  }

  async function fetchPromos() {
    const response = await getPromos(
      primaryDetails.activation_type,
      primaryDetails.classification_type
    )
    if (response.data) {
      var arr = []
      response.data.map((data) => {
        arr.push({ ...data, qty: 0 })
      })
      setPromoList(arr)
    }
  }

  useEffect(() => {
    fetchDropdownData()
    // fetchFreebies();
    // fetchAccounts();
  }, [])

  useEffect(() => {
    if (
      primaryDetails.classification_type !== "" &&
      primaryDetails.activation_type !== ""
    ) {
      fetchPromos()
    }
  }, [primaryDetails.classification_type])
  useEffect(() => {
    if (
      primaryDetails.classification_type !== "" &&
      primaryDetails.activation_type !== ""
    ) {
      fetchPromos()
    }
  }, [primaryDetails.activation_type])

  const registrationProps = {
    navigation,
    details,
    setDetails,
    primaryDetails,
    setPrimaryDetails,
    accounts,
    setAccounts,
    malls,
    setMalls,
    customers,
    setCustomers,
    issues,
    outlets,
    selectedEscalationIssues,
    setSelectedEscalationIssues,
    sqrTerminals,
    setSqrTerminals,
    merchandisingMaterials,
    setMerchandisingMaterials,
  }

  const summaryProps = {
    navigation,
    details,
    primaryDetails,
    productCodes,
    freebies,
    promoList,
    promoListLKA,
    addCustomer,
    setAddCustomer,
    formId,
    setFormId,
    checked,
    setChecked,
    checkedDiabetasol,
    setCheckedDiabetasol,
    checkedEntrasol,
    setCheckedEntrasol,
    checkedDiabetasolSweetener,
    setCheckedDiabetasolSweetener,
    malls,
    setMalls,
    customers,
    setCustomers,
    issues,
    outlets,
    selectedEscalationIssues,
    setSelectedEscalationIssues,
    sqrTerminals,
    setSqrTerminals,
    merchandisingMaterials,
    setMerchandisingMaterials,
  }

  async function fetchMerchants() {
    const response = await getAllMerchants()
    let issues_final = []
    if (response?.data) {
      response.data.data.map((val) => {
        issues_final.push({
          ...val,
          name: "merchant_id",
          var_name: "merchant_name",
          label: val.name,
          value: val.id,
        })
      })
    }
    issues_final.push({
      name: "merchant_id",
      var_name: "merchant_name",
      label: "Others",
      value: "Others",
    })
    setIssues(issues_final)
  }
  async function fetchOutlets() {
    const response = await getAllOutlets()
    let outlets_final = []
    if (response?.data) {
      response.data.data.map((val) => {
        outlets_final.push({
          ...val,
          name: "outlet_id",
          var_name: "outlet",
          label: val.name,
          value: val.id,
        })
      })
    }
    outlets_final.push({
      name: "outlet_id",
      var_name: "outlet",
      label: "Others",
      value: "Others",
    })
    setOutlets(outlets_final)
  }
  useEffect(() => {
    fetchMerchants()
    fetchOutlets()
  }, [])
  switch (step.id) {
    case "registration":
      return <RegistrationVer2 {...registrationProps} />
    // case "consumer":
    //   return <Consumer {...consumerProps} />;
    case "summary":
      return <SummaryVer2 {...summaryProps} />
  }

  return <div></div>
}

export default SwitchForm
