import React, { useState } from "react"
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Card,
  ListGroup,
} from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import ReactLoading from "react-loading"
import {
  getUserType,
  refreshPage,
  removeUserSession,
} from "../../Helpers/Utils/Common"
import { createCustomer, createInfo } from "../../Helpers/API/formsAPI"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { logoutUser } from "../../Helpers/API/authApi"
import logo from "../../Assets/Logo/kalbe.png"
import entrasol from "../../Assets/Logo/entrasol.png"
import diabetasol from "../../Assets/Logo/diabetasol.png"
import swoosh from "../../Assets/Logo/swoosh.png"
import greenbird from "../../Assets/Form/maya_greenbird.jpeg"
import version2 from "../../Assets/Form/maya_ver_2.jpeg"
import customized from "../../Assets/Form/maya_customized.jpeg"
import qrph from "../../Assets/Form/maya_qrph.jpeg"
import qrpayment from "../../Assets/Form/maya_qr_payment.jpeg"
import Select from "react-select"

function SummaryVer2({
  navigation,
  details,
  promoListLKA,
  primaryDetails,
  productCodes,
  freebies,
  promoList,
  addCustomer,
  setAddCustomer,
  formId,
  setFormId,
  checked,
  setChecked,
  checkedDiabetasol,
  setCheckedDiabetasol,
  checkedEntrasol,
  setCheckedEntrasol,
  checkedDiabetasolSweetener,
  setCheckedDiabetasolSweetener,
  malls,
  setMalls,
  customers,
  setCustomers,
  issues,
  selectedEscalationIssues,
  setSelectedEscalationIssues,
  sqrTerminals,
  setSqrTerminals,
  merchandisingMaterials,
  setMerchandisingMaterials,
}) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const user_type = getUserType()
  const [showBack, setShowBack] = useState(false)

  async function addInformation() {
    const response = await createInfo(
      // details,
      primaryDetails,
      sqrTerminals
      // productCodes,
      // freebies,
      // promoList
    )
    // console.log(response)
    if (response.data) {
      setFormId(response.data.form_id)
      toast.success("Information Successfully Saved", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      })
      setOpenModal(true)
      setLoading(false)
    } else {
      toast.error("Failed to Save Information", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      })
      setLoading(false)
    }
  }

  async function handleLogout() {
    removeUserSession()
    navigate("/")
  }

  function handleSubmit() {
    setLoading(true)
    // setOpenModal(true);	//e comment nya ni if nanay api
    addInformation()
  }

  function handleAnotherRegistration() {
    refreshPage()
  }

  function handleAnotherStore() {
    for (const [key, value] of Object.entries(details)) {
      details[key] = ""
    }

    // setprimaryDetails.Activation_type("")
    navigation.go("registration")
    setOpenModal(false)
  }

  function handleBack() {
    setShowBack(true)
  }

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-md-center p-5 mt-10">
        <Form.Label className="font-bold">Summary </Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="typeOfStore">
            <Form.Label className="font-med">
              Merchant Name <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="merchant_name"
              type="text"
              value={primaryDetails.merchant_name}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="dbaOutlet">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  DBA + Outlet <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Example: ADIDAS GLORIETTA 2</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Form.Control
              name="outlet"
              type="text"
              value={primaryDetails.outlet}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label className="font-med">
              City <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={primaryDetails.city}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="brgy">
            <Form.Label className="font-med">
              Brgy <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="brgy"
              type="text"
              value={primaryDetails.brgy}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="postal_code">
            <Form.Label className="font-med">
              Postal Code <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="postal_code"
              type="text"
              value={primaryDetails.postal_code}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="floor_no">
            <Form.Label className="font-med">
              Slot/Floor <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="floor_no"
              type="text"
              value={primaryDetails.floor_no}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="building">
            <Form.Label className="font-med">
              Building <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="building"
              type="text"
              value={primaryDetails.building}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="location">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Coordinates <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<a
									className="add-supplier-label font-regular font-10"
									href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop"
									target="_blank"
								>
									How to get coordinates?
								</a>
							</Col> */}
              </Row>
            </Form.Label>
            <Form.Control
              name="coordinates"
              type="text"
              value={primaryDetails.coordinates}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="maya_merchant">
            <Form.Label className="font-med ">
              Maya Merchant <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-maya_merchant-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="maya_merchant"
                    type={type}
                    id={`inline-maya_merchant-${type}-2`}
                    defaultChecked={primaryDetails.maya_merchant === "1"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="maya_merchant"
                    type={type}
                    id={`inline-maya_merchant-${type}-3`}
                    defaultChecked={primaryDetails.maya_merchant === "0"}
                    disabled
                  />
                </>
              </div>
            ))}
          </Form.Group>
          {primaryDetails.maya_merchant === "1" && (
            <>
              <Form.Group className="mb-3" controlId="midsqr">
                <Form.Label className="font-med">
                  <Col xs={12}>
                    Maya Merchant MID - SQR{" "}
                    <span className="required-badge">*</span>
                  </Col>
                  <Col xs={12}>
                    <span className="font-regular font-10">
                      If not merchant, type N/A
                    </span>
                  </Col>
                </Form.Label>
                <Form.Control
                  name="merchant_mid_sqr"
                  type="text"
                  value={primaryDetails.merchant_mid_sqr}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="midterminal">
                <Form.Label className="font-med">
                  <Col xs={12}>
                    Maya Merchant MID - Terminal{" "}
                    <span className="required-badge">*</span>
                  </Col>
                  <Col xs={12}>
                    <span className="font-regular font-10">
                      If not merchant, type N/A
                    </span>
                  </Col>
                </Form.Label>
                <Form.Control
                  name="merchant_mid_terminal"
                  type="text"
                  value={primaryDetails.merchant_mid_terminal}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="sn_terminal">
                <Form.Label className="font-med">
                  Terminal SN
                  {primaryDetails.sn_terminal !== "" && (
                    <span className="required-badge">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  name="sn_terminal"
                  type="text"
                  value={primaryDetails.sn_terminal}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="store_code">
                <Form.Label className="font-med">
                  SQR Store Code
                  {primaryDetails.store_code !== "" && (
                    <span className="required-badge">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  name="store_code"
                  type="text"
                  value={primaryDetails.store_code}
                  disabled
                />
              </Form.Group>
            </>
          )}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="font-med">
              Name <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={primaryDetails.name}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="number">
            <Form.Label className="font-med">
              Number <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="number"
              type="text"
              maxLength={11}
              value={primaryDetails.number}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label className="font-med">
              Email <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="email"
              type="text"
              value={primaryDetails.email}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="maya_solutions">
            <Form.Label className="font-med">
              Maya Solutions <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-mall_tagging-${type}`} className="mb-3">
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Terminal"
                        value="Terminal"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-3`}
                        defaultChecked={
                          primaryDetails.maya_solutions === "Terminal"
                        }
                        disabled
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="SQR"
                        value="SQR"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-4`}
                        defaultChecked={primaryDetails.maya_solutions === "SQR"}
                        disabled
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Terminal & SQR"
                        value="Terminal & SQR"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-5`}
                        defaultChecked={
                          primaryDetails.maya_solutions === "Terminal & SQR"
                        }
                        disabled
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Online"
                        value="online"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-7`}
                        defaultChecked={
                          primaryDetails.maya_solutions === "online"
                        }
                        disabled
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="No Solution"
                        value="No Solution"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-6`}
                        defaultChecked={
                          primaryDetails.maya_solutions === "No Solution"
                        }
                        disabled
                      />
                    </Col>
                    {/* <Col xs={12}>
                                    <Form.Check
                                        inline
                                        label="N/A"
                                        value="N/A"
                                        name="maya_solutions"
                                        type={type}
                                        id={`inline-maya_solutions-${type}-7`}
                                        defaultChecked={primaryDetails.maya_solutions === "N/A"}
                                        disabled
                                    />
                                </Col> */}
                  </Row>
                </>
              </div>
            ))}
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="terminalAvailable">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Terminal/s Available {" "}
								<span className="required-badge">*{" "}</span>
							</Col>
							
						</Row>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Gcash"
								value="terminal_gcash"
								id="terminal_gcash"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_gcash}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="BDO"
								value="terminal_BDO"
								id="terminal_BDO"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_BDO}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Shopee"
								value="terminal_shopee"
								id="terminal_shopee"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_shopee}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Alipay"
								value="terminal_alipay"
								id="terminal_alipay"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_alipay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="GoTyme"
								value="terminal_goTyme"
								id="terminal_goTyme"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_goTyme}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Union Pay"
								value="terminal_union_pay"
								id="terminal_union_pay"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_union_pay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Atome"
								value="terminal_atome"
								id="terminal_atome"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_atome}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="GrabPay"
								value="terminal_grabPay"
								id="terminal_grabPay"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_grabPay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="BookyPay"
								value="terminal_bookyPay"
								id="terminal_bookyPay"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_bookyPay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Hello Money"
								value="terminal_hello_money"
								id="terminal_hello_money"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_hello_money}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="WeChat"
								value="terminal_weChat"
								id="terminal_weChat"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_weChat}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Plentina"
								value="terminal_plentina"
								id="terminal_plentina"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_plentina}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Others"
								value="terminal_other"
								id="terminal_other"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_other}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="None"
								value="terminal_none"
								id="terminal_none"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_none}
								disabled
							/>
						</Col>
						{
							primaryDetails.terminal_other && (
								<Col xs={12}>
									<Form.Control
										name="available_terminal_other_details"
										type="text"
										value={primaryDetails.available_terminal_other_details}
										disabled
									/>
								</Col>
							)
						}
					</Row>
				</Form.Group> */}
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Competitor Solution Device{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Cash"
                  value="competitor_solutions_Cash"
                  id="competitor_solutions_Cash"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_Cash}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BDO"
                  value="competitor_solutions_BDO"
                  id="competitor_solutions_BDO"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_BDO}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Metrobank"
                  value="competitor_solutions_metrobank"
                  id="competitor_solutions_metrobank"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_metrobank}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Unionbank"
                  value="competitor_solutions_unionbank"
                  id="competitor_solutions_unionbank"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_unionbank}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Global Payments"
                  value="competitor_solutions_global_payments"
                  id="competitor_solutions_global_payments"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_global_payments}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="HSBC"
                  value="competitor_solutions_HSBC"
                  id="competitor_solutions_HSBC"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_HSBC}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Maybank"
                  value="competitor_solutions_maybank"
                  id="competitor_solutions_maybank"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_maybank}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="RCBC"
                  value="competitor_solutions_RCBC"
                  id="competitor_solutions_RCBC"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_RCBC}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Stripe Payments"
                  value="competitor_solutions_stripe_payments"
                  id="competitor_solutions_stripe_payments"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_stripe_payments}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="GHL"
                  value="competitor_solutions_GHL"
                  id="competitor_solutions_GHL"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_GHL}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="competitor_solutions_other"
                  id="competitor_solutions_other"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_other}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="competitor_solutions_none"
                  id="competitor_solutions_none"
                  name="competitor_solutions"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_none}
                  disabled
                />
              </Col>
              {primaryDetails.competitor_solutions_other && (
                <Col xs={12}>
                  <Form.Control
                    name="solution_device_other_details"
                    type="text"
                    value={primaryDetails.solution_device_other_details}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  SQR/Terminal Available
                  <span className="required-badge">* </span>
                </Col>
              </Row>
            </Form.Label>
            <Row>
              {sqrTerminals.map((data, key) => (
                <Col xs={12}>
                  <Form.Check
                    inline
                    label={data.value}
                    value={data.value}
                    type="checkbox"
                    checked={data.selected}
                    disabled
                  />
                </Col>
              ))}

              {/* <InputError
                isValid={isError.solution_device}
                message={"This Field is Required*."}
              /> */}
            </Row>
          </Form.Group>
            <Form.Group className="mb-3" controlId="merchandisiong_materials">
            <Form.Label className="font-med">
              Merchandising Materials Available
              <span className="required-badge">*</span>
            </Form.Label>
            <div className="row">
              <div className="col-12" sm={12}>
                <table style={{ background: "white", color: "#232b2b" }}>
                  <thead style={{ fontSize: "x-small", color: "#232b2b" }}>
                    <tr>
                      <th> </th>
                      <th
                        align="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        Door Hanger
                      </th>
                      <th
                        align="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        QR Standee
                      </th>
                      <th
                        align="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        Terminal Standee
                      </th>
                      <th
                        align="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        Billfold
                      </th>
                      <th
                        align="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        Other Merch Mats
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {merchandisingMaterials.map((data, key) => (
                      <tr>
                        <td style={{ fontSize: "small" }} align="center">
                          {data.name}
                        </td>
                        <td>
                          {" "}
                          <Form.Check
                            inline
                            name="door_hanger"
                            type="checkbox"
                            disabled
                            checked={data.door_hanger}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="qr_standee"
                            type="checkbox"
                            disabled
                            checked={data.qr_standee}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="terminal_standee"
                            type="checkbox"
                            disabled
                            checked={data.terminal_standee}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="billfold"
                            type="checkbox"
                            disabled
                            checked={data.billfold}
                          />
                        </td>
                        <td align="center" style={{ textAlign: "end" }}>
                          <Form.Check
                            inline
                            name="other_merch_mats"
                            type="checkbox"
                            disabled
                            checked={data.other_merch_mats}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Form.Group>
          <Form.Label className="font-med">
            <Row>
              <Col xs={12}>Out of 10 transactions, how many are:</Col>
            </Row>
          </Form.Label>
          <Form.Group className="mb-3" controlId="maya_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Maya <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="maya_transaction"
              type="text"
              value={primaryDetails.maya_transaction}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="gcash_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Gcash <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="gcash_transaction"
              type="text"
              value={primaryDetails.gcash_transaction}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="cash_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Cash <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="cash_transaction"
              type="text"
              value={primaryDetails.cash_transaction}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="other_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Others <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="other_transaction_details"
              type="text"
              className="mb-2"
              placeholder="Type of payment made"
              value={primaryDetails.other_transaction_details}
              disabled
            />
            <Form.Control
              name="other_transaction"
              type="text"
              placeholder="How many"
              value={primaryDetails.other_transaction}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Own Merchandising Material{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Gcash"
                  value="merchandising_material_gcash"
                  id="merchandising_material_gcash"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_gcash}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BDO"
                  value="merchandising_material_BDO"
                  id="merchandising_material_BDO"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_BDO}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Shopee"
                  value="merchandising_material_shopee"
                  id="merchandising_material_shopee"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_shopee}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Atome"
                  value="merchandising_material_atome"
                  id="merchandising_material_atome"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_atome}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchandising_material_other"
                  id="merchandising_material_other"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_other}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="merchandising_material_none"
                  id="merchandising_material_none"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_none}
                  disabled
                />
              </Col>
              {primaryDetails.merchandising_material_other && (
                <Col xs={12}>
                  <Form.Control
                    name="own_merchandising_other_details"
                    type="text"
                    value={primaryDetails.own_merchandising_other_details}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Awareness of QRPh <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-qrph_known-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="qrph_known"
                    type={type}
                    id={`inline-qrph_known-${type}-2`}
                    defaultChecked={primaryDetails.qrph_known === "1"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="qrph_known"
                    type={type}
                    id={`inline-qrph_known-${type}-3`}
                    defaultChecked={primaryDetails.qrph_known === "0"}
                    disabled
                  />
                </>
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Merchandising Restrictions{" "}
              <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div
                key={`inline-merchandising_restrictions-${type}`}
                className="mb-3"
              >
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="merchandising_restrictions"
                    type={type}
                    id={`inline-merchandising_restrictions-${type}-2`}
                    defaultChecked={
                      primaryDetails.merchandising_restrictions === "1"
                    }
                    disabled
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="merchandising_restrictions"
                    type={type}
                    id={`inline-merchandising_restrictions-${type}-3`}
                    defaultChecked={
                      primaryDetails.merchandising_restrictions === "0"
                    }
                    disabled
                  />
                </>
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="merchant_concern">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Merchant Concern <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Competitor Priority"
                  value="merchant_concern_competitor_priority"
                  id="merchant_concern_competitor_priority"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_competitor_priority}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Closed Store"
                  value="merchant_concern_closed_store"
                  id="merchant_concern_closed_store"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_closed_store}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Pullout"
                  value="merchant_concern_pullout"
                  id="merchant_concern_pullout"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_pullout}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Pending POS/QR"
                  value="merchant_concern_pending_pos_or_qr"
                  id="merchant_concern_pending_pos_or_qr"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_pending_pos_or_qr}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Account Issue"
                  value="merchant_concern_account_issue"
                  id="merchant_concern_account_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_account_issue}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="High cash payment"
                  value="merchant_concern_high_cash_payment"
                  id="merchant_concern_high_cash_payment"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_high_cash_payment}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Low foot traffic"
                  value="merchant_concern_low_foot_traffic"
                  id="merchant_concern_low_foot_traffic"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_low_foot_traffic}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Update name"
                  value="merchant_concern_update_name"
                  id="merchant_concern_update_name"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_update_name}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Change tagging"
                  value="merchant_concern_change_tagging"
                  id="merchant_concern_change_tagging"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_change_tagging}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Maya manager issue"
                  value="merchant_concern_maya_manager_issue"
                  id="merchant_concern_maya_manager_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_maya_manager_issue}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchant_concern_other"
                  id="merchant_concern_other"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_other}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No Issue"
                  value="merchant_concern_no_issue"
                  id="merchant_concern_no_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_no_issue}
                  disabled
                />
              </Col>
              {primaryDetails.merchant_concern_other && (
                <Col xs={12}>
                  <Form.Control
                    name="merchant_concern_other_details"
                    type="text"
                    value={primaryDetails.merchant_concern_other_details}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="servicing">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Servicing <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Poor connection"
                  value="servicing_poor_connection"
                  id="servicing_poor_connection"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_poor_connection}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Settlement issue"
                  value="servicing_settlement_issue"
                  id="servicing_settlement_issue"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_settlement_issue}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Thermal rolls request"
                  value="servicing_thermal_rolls_request"
                  id="servicing_thermal_rolls_request"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_thermal_rolls_request}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Transaction failed"
                  value="servicing_transaction_failed"
                  id="servicing_transaction_failed"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_transaction_failed}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Reversal transaction"
                  value="servicing_reversal_transaction"
                  id="servicing_reversal_transaction"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_reversal_transaction}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No receipt"
                  value="servicing_no_receipt"
                  id="servicing_no_receipt"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_no_receipt}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Print problems"
                  value="servicing_print_problems"
                  id="servicing_print_problems"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_print_problems}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Terminal replacement"
                  value="servicing_terminal_replacement"
                  id="servicing_terminal_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_terminal_replacement}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Charger replacement"
                  value="servicing_charger_replacement"
                  id="servicing_charger_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_charger_replacement}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Simcard replacement"
                  value="servicing_simcard_replacement"
                  id="servicing_simcard_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_simcard_replacement}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="servicing_other"
                  id="servicing_other"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_other}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No Issue"
                  value="servicing_no_issue"
                  id="servicing_no_issue"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_no_issue}
                  disabled
                />
              </Col>
              {primaryDetails.servicing_other && (
                <Col xs={12}>
                  <Form.Control
                    name="servicing_other_details"
                    type="text"
                    value={primaryDetails.servicing_other_details}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="terminalAvailable">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Replacement {" "}
								<span className="required-badge">*{" "}</span>
							</Col>
							
						</Row>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Terminal replacement"
								value="replacement_terminal_replacement"
								id="replacement_terminal_replacement"
								name="replacement"
								type="checkbox"
								checked={primaryDetails.replacement_terminal_replacement}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Charger replacement"
								value="replacement_charger_replacement"
								id="replacement_charger_replacement"
								name="replacement"
								type="checkbox"
								checked={primaryDetails.replacement_charger_replacement}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Simcard replacement"
								value="replacement_simcard_replacement"
								id="replacement_simcard_replacement"
								name="replacement"
								type="checkbox"
								checked={primaryDetails.replacement_simcard_replacement}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="No Issue"
								value="replacement_no_issue"
								id="replacement_no_issue"
								name="replacement"
								type="checkbox"
								checked={primaryDetails.replacement_no_issue}
								disabled
							/>
						</Col>
					</Row>
				</Form.Group> */}
          <Form.Group className="mb-3" controlId="merch_req">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Merchandising Request{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Standee"
                  value="merchandising_request_standee"
                  id="merchandising_request_standee"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_standee}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Door Hanger"
                  value="merchandising_request_door_hanger"
                  id="merchandising_request_door_hanger"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_door_hanger}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Billfolds"
                  value="merchandising_request_billfolds"
                  id="merchandising_request_billfolds"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_billfolds}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Ballpen"
                  value="merchandising_request_ballpen"
                  id="merchandising_request_ballpen"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_ballpen}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Tarpaulin"
                  value="merchandising_request_tarpaulin"
                  id="merchandising_request_tarpaulin"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_tarpaulin}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchandising_request_other"
                  id="merchandising_request_other"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_other}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="merchandising_request_none"
                  id="merchandising_request_none"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_none}
                  disabled
                />
              </Col>
              {primaryDetails.merchandising_request_other && (
                <Col xs={12}>
                  <Form.Control
                    name="merchandising_request_other_details"
                    type="text"
                    value={primaryDetails.merchandising_request_other_details}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Retraining Concern <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-retraining_concern-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="retraining_concern"
                    type={type}
                    id={`inline-retraining_concern-${type}-2`}
                    defaultChecked={primaryDetails.retraining_concern === "1"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="retraining_concern"
                    type={type}
                    id={`inline-retraining_concern-${type}-3`}
                    defaultChecked={primaryDetails.retraining_concern === "0"}
                    disabled
                  />
                </>
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Done/Resolved <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-is_resolved-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="is_resolved"
                    type={type}
                    id={`inline-is_resolved-${type}-2`}
                    defaultChecked={primaryDetails.is_resolved === "1"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Not yet"
                    value="0"
                    name="is_resolved"
                    type={type}
                    id={`inline-is_resolved-${type}-3`}
                    defaultChecked={primaryDetails.is_resolved === "0"}
                    disabled
                  />
                </>
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalation">
            <Form.Label className="font-med">
              Ongoing Escalation/Remarks
            </Form.Label>
            <Form.Control
              name="escalation"
              type="text"
              value={primaryDetails.escalation}
              disabled
            />
          </Form.Group>
        

          <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
            <Button
              variant="primary"
              size="md"
              className="full-width-btn confirm-btn logout"
              onClick={handleBack}
            >
              Back
            </Button>
          </Form.Group>
          <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
            {loading ? (
              <Row className="justify-content-md-center">
                <Col md={1} sm={1} xs={1} className="left-38">
                  <ReactLoading
                    type="spinningBubbles"
                    color="#87BD40"
                    height={100}
                    width={50}
                  />
                </Col>
              </Row>
            ) : (
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn"
                onClick={handleSubmit}
              >
                Confirm & Submit
              </Button>
            )}
          </Form.Group>
        </Form>
      </Row>
      {/* <img src={swoosh} alt="logo" height={250} className={"swoosh"} /> */}
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Row style={{ display: "contents" }}>
            <span className="title" style={{ textAlign: "center" }}>
              INFORMATION SUCCESSFULLY SAVED!
            </span>
          </Row>
        </Modal.Header>
        <Modal.Body className="action">
          <Form>
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn"
                onClick={handleAnotherRegistration}
              >
                Register Another Merchant
              </Button>
            </Form.Group>
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn logout "
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showBack}
        onHide={() => setShowBack(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Row className="text-center">
            <span className="title" style={{ color: "#d00000" }}>
              ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?
            </span>
          </Row>
        </Modal.Header>
        <Modal.Body className="action">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                variant="primary"
                size="md"
                className=" confirm-btn full-width-btn"
                onClick={() => navigation.previous()}
              >
                YES
              </Button>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn logout"
                onClick={() => setShowBack(false)}
              >
                NO
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default SummaryVer2
