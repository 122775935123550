import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Card,
  ListGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import {
  getUserType,
  refreshPage,
  removeUserSession,
} from "../../Helpers/Utils/Common";
import { createCustomer, createInfo } from "../../Helpers/API/formsAPI";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutUser } from "../../Helpers/API/authApi";
import logo from "../../Assets/Logo/kalbe.png";
import entrasol from "../../Assets/Logo/entrasol.png";
import diabetasol from "../../Assets/Logo/diabetasol.png";
import swoosh from "../../Assets/Logo/swoosh.png";
import greenbird from "../../Assets/Form/maya_greenbird.jpeg"
import version2 from "../../Assets/Form/maya_ver_2.jpeg"
import customized from "../../Assets/Form/maya_customized.jpeg"
import qrph from "../../Assets/Form/maya_qrph.jpeg"
import qrpayment from "../../Assets/Form/maya_qr_payment.jpeg"
import Select from "react-select";

function Summary({
  navigation,
  details,
  promoListLKA,
  primaryDetails,
  productCodes,
  freebies,
  promoList,
  addCustomer,
  setAddCustomer,
  formId,
  setFormId,
  checked,
  setChecked,
  checkedDiabetasol,
  setCheckedDiabetasol,
  checkedEntrasol,
  setCheckedEntrasol,
  checkedDiabetasolSweetener,
  setCheckedDiabetasolSweetener,
  malls,
  setMalls,
  customers,
  setCustomers,
  issues,
  selectedEscalationIssues,
  setSelectedEscalationIssues
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const user_type = getUserType();
  const [showBack, setShowBack] = useState(false);

  async function addInformation() {
	const response = await createInfo(
        // details,
        primaryDetails,
        // productCodes,
        // freebies,
        // promoList
      );
		// console.log(response)
      if (response.data) {
        setFormId(response.data.form_id);
        toast.success("Information Successfully Saved", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setOpenModal(true);
        setLoading(false);
      } else {
        toast.error("Failed to Save Information", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setLoading(false);
      }
  }

  async function handleLogout() {
    removeUserSession();
    navigate("/");
  }

  function handleSubmit() {
    setLoading(true);
    // setOpenModal(true);	//e comment nya ni if nanay api
    addInformation();
  }

  function handleAnotherRegistration() {
	refreshPage()
  }

  function handleAnotherStore() {
    for (const [key, value] of Object.entries(details)) {
      details[key] = "";
    }

    // setprimaryDetails.Activation_type("")
    navigation.go("registration");
    setOpenModal(false);
  }

  function handleBack() {
    setShowBack(true);
  }

  return (
    <Container>
      <ToastContainer />
      	<Row className="justify-content-md-center p-5 mt-10">
			<Form.Label className="font-bold">
				Summary{" "}
			</Form.Label>
			<Form style={{ textAlign: "left" }}>
			
				{/* <Form.Group className="mb-3" controlId="fullName">
					<Form.Label className="font-med">
						Full Name{" "}
						<span className="required-badge">*{" "}</span>
						<span className="font-regular font-10">(First Name, Last Name)</span>
					</Form.Label>
					<Form.Control
						name="full_name"
						type="dropdown"
						value={primaryDetails.full_name}
						disabled
					/>
				</Form.Group> */}
				{/* {
					primaryDetails.full_name === "Others" && (
						<Form.Group className="mb-3" controlId="fullNameOther">
							<Form.Label className="font-med">
								Full Name (Other) <span className="required-badge">*</span>
							</Form.Label>
							<Form.Control
								name="full_name_other"
								type="text"
								value={primaryDetails.full_name_other}
								disabled
								/>
						</Form.Group>
					)
				} */}
				<Form.Group className="mb-3" controlId="typeOfStore">
					<Form.Label className="font-med">
						Type of Store <span className="required-badge">*</span>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Glass Door"
								value="Glass Door"
								name="store_type"
								type="radio"
								defaultChecked={primaryDetails.store_type === "Glass Door"}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Casual Seller"
								value="Casual Seller"
								name="store_type"
								type="radio"
								defaultChecked={primaryDetails.store_type === "Casual Seller"}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Kiosk"
								value="Kiosk"
								name="store_type"
								type="radio"
								defaultChecked={primaryDetails.store_type === "Kiosk"}
								disabled
							/>
							
						</Col>
					</Row>
					{/* {
						["radio"].map((type) => (
						<div key={`inline-store_type-${type}`} className="mb-3">
						<>
						</>
						</div>
					))} */}
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTagging">
					<Form.Label className="font-med">
						Mall Tagging <span className="required-badge">*</span>
					</Form.Label>
					{
						["radio"].map((type) => (
						<div key={`inline-mall_tagging-${type}`} className="mb-3">
							<>
								<Form.Check
									inline
									label="Mall Based"
									value="Mall Based"
									name="mall_tagging"
									type={type}
									id={`inline-mall_tagging-${type}-3`}
									defaultChecked={primaryDetails.mall_tagging === "Mall Based"}
									disabled
								/>
								<Form.Check
									inline
									label="Stand Alone"
									value="Stand Alone"
									name="mall_tagging"
									type={type}
									id={`inline-mall_tagging-${type}-4`}
									defaultChecked={primaryDetails.mall_tagging === "Stand Alone"}
									disabled
								/>
							</>
						</div>
					))}
				</Form.Group>
				{
					primaryDetails.mall_tagging === "Mall Based" && (
						<Form.Group className="mb-3" controlId="fullName">
							<Form.Label className="font-med">
								<Row>
									<Col xs={12}>
										Name of Mall{" "}
										<span className="required-badge">*{" "}</span>
									</Col>
									<Col xs={12}>
										<span className="font-regular font-10">Follow the format: (SM MEGAMALL, SM EAST ORTIGAS, AYALA MALLS CIRCUIT)</span>
									</Col>
								</Row>
							</Form.Label>
								<Form.Control
									name="mall_name"
									type="text"
									value={primaryDetails.mall_name}
									disabled
								/>
						</Form.Group>
					)
				}
				{
					(primaryDetails.mall_tagging === "Mall Based" && primaryDetails.mall_name === "Others") && (
						<Form.Group className="mb-3" controlId="mallTaggingOther">
							<Form.Label className="font-med">
								Mall Name (Other) <span className="required-badge">*</span>
							</Form.Label>
							<Form.Control
								name="mall_name_other"
								type="text"
								value={primaryDetails.mall_name_other}
								disabled
							/>
						</Form.Group>
					)
				}
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Merchant Name {" "}<span className="required-badge">*{" "}</span>
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">(Business Registered Name from their business permit)</span>
							</Col>
						</Row>
					</Form.Label>
					<Form.Control
						name="merchant_name"
						type="text"
						value={primaryDetails.merchant_name}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								DBA + Outlet <span className="required-badge">*{" "}</span>
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">Example: ADIDAS GLORIETTA 2</span>
							</Col>
						</Row>
					</Form.Label>
					<Form.Control
						name="outlet"
						type="text"
						value={primaryDetails.outlet}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								BLDG <span className="required-badge">*{" "}</span>
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">EX.  BLDG A</span>
							</Col>
						</Row>
					</Form.Label>
					<Form.Control
						name="bldg"
						type="text"
						// value={"Northwing"}
						value={primaryDetails.bldg}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								FLR# <span className="required-badge">*{" "}</span>
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">EX. 3RD FLOOR</span>
							</Col>
						</Row>
					</Form.Label>
					<Form.Control
						name="floor_no"
						type="text"
						value={primaryDetails.floor_no}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Location/Coordinates <span className="required-badge">*{" "}</span>
							</Col>
							{/* <Col xs={12}>
								<span className="font-regular font-10">EX. 3RD FLOOR</span>
							</Col> */}
						</Row>
					</Form.Label>
					<Form.Control
						name="coordinates"
						type="text"
						value={primaryDetails.coordinates}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="availableSqr">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Available SQR/s {" "}
								{/* <span className="required-badge">*{" "}</span> */}
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col>
						</Row>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Maya"
								value="sqr_maya"
								name="available_sqr"
								type="checkbox"
								checked={primaryDetails.sqr_maya}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Gcash"
								value="sqr_gcash"
								name="available_sqr"
								type="checkbox"
								checked={primaryDetails.sqr_gcash}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Shopee Pay"
								value="sqr_shopee_pay"
								name="available_sqr"
								type="checkbox"
								checked={primaryDetails.sqr_shopee_pay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="BDO Pay"
								value="sqr_bdo_pay"
								name="available_sqr"
								type="checkbox"
								checked={primaryDetails.sqr_bdo_pay}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Other"
								value="sqr_others"
								name="available_sqr"
								type="checkbox"
								checked={primaryDetails.sqr_others}
								disabled
							/>
						</Col>
						{
							primaryDetails.sqr_others && (
								<Col xs={12}>
									<Form.Control
										name="sqr_other"
										type="text"
										value={primaryDetails.sqr_other}
										disabled
										// disabled={!primaryDetails.sqr_others}
									/>
								</Col>
							)
						}
					</Row>
				</Form.Group>
				<Form.Group className="mb-3" controlId="typeOfStore">
					<Form.Label className="font-med">
						Gcash Acceptance Payment Available {" "}
						{/* <span className="required-badge">*{" "}</span> */}
					</Form.Label>
					{
						["radio"].map((type) => (
						<div key={`inline-gcash_acceptable_payment-${type}`} className="mb-3">
						<>
						<Row>
							<Col xs={12}>
								<Form.Check
									inline
									label="Static QR"
									value="Static QR"
									name="gcash_acceptable_payment"
									type={type}
									id={`inline-gcash_acceptable_payment-${type}-2`}
									defaultChecked={primaryDetails.gcash_payment_available === "Static QR"}
									disabled
								/>
							</Col>
							<Col xs={12}>
								<Form.Check
									inline
									label="QR inside POS/Terminal"
									value="QR inside POS/Terminal"
									name="gcash_acceptable_payment"
									type={type}
									id={`inline-gcash_acceptable_payment-${type}-3`}
									defaultChecked={primaryDetails.gcash_payment_available === "QR inside POS/Terminal"}
									disabled
								/>
							</Col>
							<Col xs={12}>
								<Form.Check
									inline
									label="Both"
									value="Both"
									name="gcash_acceptable_payment"
									type={type}
									id={`inline-gcash_acceptable_payment-${type}-4`}
									defaultChecked={primaryDetails.gcash_payment_available === "Both"}
									disabled
								/>
							</Col>
						</Row>
						</>
						</div>
					))}
				</Form.Group>
				<Form.Group className="mb-3" controlId="terminalAvailable">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Terminal/s Available {" "}
								{/* <span className="required-badge">*{" "}</span> */}
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col>
						</Row>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Maya"
								value="terminal_maya"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_maya}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="BDO"
								value="terminal_BDO"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_BDO}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Metrobank"
								value="terminal_metrobank"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_metrobank}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Global Payments"
								value="terminal_global_payments"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_global_payments}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="GHL"
								value="terminal_ghl"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_ghl}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Unionbank"
								value="terminal_unionbank"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_unionbank}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="HSBC"
								value="terminal_hsbc"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_hsbc}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Maybank"
								value="terminal_maybank"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_maybank}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="RCBC"
								value="terminal_rcbc"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_rcbc}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Stripe Payments"
								value="terminal_stripe_payments"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_stripe_payments}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Other"
								value="terminal_other"
								name="available_terminal"
								type="checkbox"
								checked={primaryDetails.terminal_other}
								disabled
							/>
						</Col>
						{
							primaryDetails.terminal_other && (
								<Col xs={12}>
									<Form.Control
										name="terminal_available_other"
										type="text"
										value={primaryDetails.terminal_available_other}
										disabled
									/>
								</Col>
							)
						}
					</Row>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						Contact Person (spoc)  {" "}
						 {/* <span className="required-badge">*{" "}</span> */}
					</Form.Label>
					<Form.Control
						name="contact_person"
						type="text"
						value={primaryDetails.contact_person}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="mallTaggingOther">
					<Form.Label className="font-med">
						Contact Number (spoc)  {" "} 
						{/* <span className="required-badge">*{" "}</span> */}
					</Form.Label>
					<Form.Control
						name="contact_number"
						type="text"
						maxLength={11}
						value={primaryDetails.contact_number}
						disabled
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="typeOfStore">
					<Form.Label className="font-med">
						<Row>
							<Col xs={12}>
								Maya Status <span className="required-badge">*{" "}</span>
							</Col>
							<Col xs={12}>
								<span className="font-regular font-10">(Maya Merchant or Non-Maya Merchant)</span>
							</Col>
						</Row>
					</Form.Label>
					<Row>
						<Col xs={12}>
							<Form.Check
								inline
								label="Maya"
								value="Maya"
								name="maya_status"
								type="radio"
								defaultChecked={primaryDetails.maya_status === "Maya"}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Non-Maya"
								value="Non-Maya"
								name="maya_status"
								type="radio"
								defaultChecked={primaryDetails.maya_status === "Non-Maya"}
								disabled
							/>
						</Col>
						<Col xs={12}>
							<Form.Check
								inline
								label="Non-Entertained"
								value="Non-Entertained"
								name="maya_status"
								type="radio"
								defaultChecked={primaryDetails.maya_status === "Non-Entertained"}
								disabled
							/>
						</Col>
					</Row>
				</Form.Group>
				{
					primaryDetails.maya_status === "Maya" && (
						<>
						<Form.Group className="mb-3" controlId="typeOfStore">
						<Form.Label className="font-med">
							Maya Visibility <span className="required-badge">*</span>
						</Form.Label>
						<Row>
							<Col xs={12}>
								<Form.Check
									inline
									label="Standee"
									value="maya_visibility_standee"
									name="maya_visibility"
									type="checkbox"
									checked={primaryDetails.maya_visibility_standee}
									disabled
								/>
							</Col>
							<Col xs={12}>
								<Form.Check
									inline
									label="Door Hanger"
									value="maya_visibility_door_hanger"
									name="maya_visibility"
									type="checkbox"
									checked={primaryDetails.maya_visibility_door_hanger}
									disabled
								/>
							</Col>
							<Col xs={12}>
								<Form.Check
									inline
									label="None"
									value="maya_visibility_none"
									name="maya_visibility"
									type="checkbox"
									checked={primaryDetails.maya_visibility_none}
									disabled
								/>
							</Col>
						</Row>
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Merch Type (FOR MAYA MERCHANTS ONLY) 
								{/* <span className="required-badge">*</span> */}
							</Form.Label>
							{
								["checkbox"].map((type) => (
									<div key={`inline-available_terminal-${type}`} className="mb-3">
									<>
										<Form.Check
											inline
											label="Greenbird or Version 1.5"
											value="merch_type_version_1"
											name="merch_type"
											type="checkbox"
											id={`inline-merch_type-${type}-4`}
											checked={primaryDetails.merch_type_greenbird}
											disabled
										/>
										<div className='row justify-content-center mb-4'>
											<div className='mb-2' style={{textAlignLast:"center"}} alt="no data found">
												<img src={greenbird} height={150}/>
											</div>
										</div>
										<Form.Check
											inline
											label="Version 2.0"
											value="merch_type_version_2"
											name="merch_type"
											type="checkbox"
											id={`inline-merch_type-${type}-4`}
											checked={primaryDetails.merch_type_version_2}
											disabled
										/>
										<div className='row justify-content-center mb-4'>
											<div className='mb-2' style={{textAlignLast:"center"}} alt="no data found">
												<img src={version2} height={150}/>
											</div>
										</div>
										<Form.Check
											inline
											label="Customized/Co-branded by Maya"
											value="merch_type_customized"
											name="merch_type"
											type="checkbox"
											id={`inline-merch_type-${type}-4`}
											checked={primaryDetails.merch_type_customized}
											disabled
										/>
										<div className='row justify-content-center mb-4'>
											<div className='mb-2' style={{textAlignLast:"center"}} alt="no data found">
												<img src={customized} height={150}/>
											</div>
										</div>
										<Form.Check
											inline
											label="Own Merch (Not from MAYA)"
											value="merch_type_own"
											name="merch_type"
											type="checkbox"
											id={`inline-merch_type-${type}-4`}
											checked={primaryDetails.merch_type_own}
											disabled
										/>
									</>
									</div>
								))}
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Merch Restriction <span className="required-badge">*</span>
							</Form.Label>
							<Row>
								<Col xs={12}>
									<Form.Check
										inline
										label="Clean Door Policy"
										value="merch_restriction_clean_door_policy"
										name="merch_restriction"
										type="checkbox"
										checked={primaryDetails.merch_restriction_clean_door_policy}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Clean Counter Policy"
										value="merch_restriction_clean_counter_policy"
										name="merch_restriction"
										type="checkbox"
										checked={primaryDetails.merch_restriction_clean_counter_policy}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="No Restrictions"
										value="merch_restriction_no_restrictions"
										name="merch_restriction"
										type="checkbox"
										checked={primaryDetails.merch_restriction_no_restrictions}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="No Area"
										value="merch_restriction_no_area"
										name="merch_restriction"
										type="checkbox"
										checked={primaryDetails.merch_restriction_no_area}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Custom"
										value="merch_restriction_custom"
										name="merch_restriction"
										type="checkbox"
										checked={primaryDetails.merch_restriction_custom}
										disabled
									/>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Alam niyo ba yung QRPh? {" "}
								 {/* <span className="required-badge">*</span> */}
								<div className="text-justify">
									<span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
								</div>
							</Form.Label>
							<div className='row justify-content-center mb-4'>
								<div className='mb-2' style={{textAlignLast:"center"}} alt="no data found">
									<img src={qrph} height={200}/>
								</div>
							</div>
							{
								["radio"].map((type) => (
								<div key={`inline-qr_ph-${type}`} className="mb-3">
								<>
										<Form.Check
											inline
											label="Yes"
											value="Yes"
											name="qr_ph"
											type={type}
											id={`inline-qr_ph-${type}-2`}
											defaultChecked
											// defaultChecked={primaryDetails.qr_ph === "Yes"}
											disabled
										/>
										<Form.Check
											inline
											label="No"
											value="No"
											name="qr_ph"
											type={type}
											id={`inline-qr_ph-${type}-3`}
											defaultChecked={primaryDetails.qr_ph === "No"}
											disabled
										/>
								</>
								</div>
							))}
						</Form.Group>
						<Form.Group className="mb-3" controlId="mallTaggingOther">
							<Form.Label className="font-med">
							<Row>
								<Col xs={12}>
									How many Maya devices are there? (Terminals){" "}
								</Col>
								<Col xs={12}>
									<span className="font-regular font-10">Put 0 if none</span>
								</Col>
							</Row>
							</Form.Label>
							<Form.Control
								name="maya_devices"
								type="text"
								value={primaryDetails.no_of_devices}
								disabled
							/>
						</Form.Group>
						{
							primaryDetails.serial_numbers.map((data, index) => {
								return (
									<Form.Group className="mb-3" controlId="mallTaggingOther">
										<Form.Label className="font-med">
											Device Serial Number (For terminal products){" "}
										</Form.Label>
										<Form.Control
											name="device_serial_number"
											type="text"
											value={data}
											disabled
										/>
									</Form.Group>
								)
							})
						}
						<Form.Group className="mb-3" controlId="mallTaggingOther">
							<Form.Label className="font-med">
							<Row>
								<Col xs={12}>
									How many Maya SQRs are there? {"  "}
								</Col>
								<Col xs={12}>
									<span className="font-regular font-10">Put 0 if none</span>
								</Col>
							</Row>
							</Form.Label>
							<Form.Control
								name="maya_sqrs"
								type="text"
								value={primaryDetails.no_of_sqr}
								disabled
							/>
						</Form.Group>
						{
							primaryDetails.store_codes.map((data, index) => {
								return(
									<Form.Group className="mb-3" controlId="mallTaggingOther">
										<Form.Label className="font-med">
											Store Code (For SQR Product){" "}
										</Form.Label>
										<Form.Control
											name="store_code"
											type="text"
											value={data}
											disabled
										/>
									</Form.Group>
								)
							})
						}
						<Form.Group className="mb-3" controlId="mallTaggingOther">
							<Form.Label className="font-med">
								Action Code/Remarks
							</Form.Label>
							<Form.Control
								name="remarks"
								type="text"
								value={primaryDetails.remarks}
								disabled
							/>
						</Form.Group>
						</>
					)
				}
				{
					primaryDetails.maya_status === "Non-Maya" && (
						<>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Tumatangap ba kayo ng Maya QR payments? {" "}
								<span className="font-regular font-10">For Merchants with Non-Maya SQR</span>
							</Form.Label>
							<div className='row justify-content-center mb-4'>
								<div className='mb-2' style={{textAlignLast:"center"}} alt="no data found">
									<img src={qrpayment} height={200}/>
								</div>
							</div>
							{
								["radio"].map((type) => (
								<div key={`inline-qr_payments-${type}`} className="mb-3">
								<>
										<Form.Check
											inline
											label="Yes"
											value="Yes"
											name="qr_payments"
											type={type}
											id={`inline-qr_payments-${type}-2`}
											defaultChecked={primaryDetails.qr_payments === "Yes"}
											disabled
										/>
										<Form.Check
											inline
											label="No"
											value="No"
											name="qr_payments"
											type={type}
											id={`inline-qr_payments-${type}-3`}
											defaultChecked={primaryDetails.qr_payments === "No"}
											disabled
										/>
								</>
								</div>
							))}
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Other merchandise visible in store{" "}
								<span className="font-regular font-10">Multiple answers</span>
							</Form.Label>
							{
								["checkbox"].map((type) => (
									<div key={`inline-merchandise_visible-${type}`} className="mb-3">
									{/* <> */}
									<Row>
										<Col xs={12}>
											<Form.Check
												inline
												label="Gcash - Door Hanger"
												value="merchandise_visible_gcash_door_hanger"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_gcash_door_hanger}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Gcash Standee"
												value="merchandise_visible_gcash_standee"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_gcash_standee}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Shopee - Door Hanger"
												value="merchandise_visible_shopee_door_hanger"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_shopee_door_hanger}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Shopee Standee"
												value="merchandise_visible_shopee_standee"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_shopee_standee}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Bdo - Door Hanger"
												value="merchandise_visible_bdo_door_hanger"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_bdo_door_hanger}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Bdo Standee"
												value="merchandise_visible_bdo_standee"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_bdo_standee}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Atome Standee"
												value="merchandise_visible_atome_standee"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_atome_standee}
												disabled
											/>
										</Col>
										<Col xs={12}>
											<Form.Check
												inline
												label="Other"
												value="merchandise_visible_other"
												name="merchandise_visible"
												type="checkbox"
												id={`inline-merchandise_visible-${type}-4`}
												checked={primaryDetails.merchandise_visible_other}
												disabled
											/>
										</Col>
									</Row>
										{
											primaryDetails.merchandise_visible_other && (
											<Col xs={12}>
												<Form.Control
													name="other_merchandise_text"
													type="text"
													value={primaryDetails.other_merchandise_text}
													disabled
												/>
											</Col>
											)
										}
									{/* </> */}
									</div>
								))
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="mallTaggingOther">
							<Form.Label className="font-med">
								Action Code/Remarks
							</Form.Label>
							<Form.Control
								name="remarks"
								type="text"
								value={primaryDetails.remarks}
								disabled
							/>
						</Form.Group>
						</>
					)
				}
				{
					primaryDetails.maya_status !== "Non-Entertained" && (
						<>
						<Form.Group className="mb-3">
							<Form.Label className="font-med">
								Escalation (Issues Encountered)
							</Form.Label>
							<Select
								name="escalation_issues"
								className="nc-modal-custom-text"
								// options={issues}
								value={selectedEscalationIssues}
								// onChange={setSelectedEscalationIssues}
								placeholder={`Select Escalation Issues`}
								isMulti={true}
								isSearchable={true}
								disabled
								styles={{
									valueContainer: (baseStyles, state) => ({
										...baseStyles,
										color: "black",
									}),
									dropdownIndicator: (baseStyles, state) => ({
										...baseStyles,
										color: "black"
										
									}),
									placeholder: (baseStyles, state) => ({
										...baseStyles,
										color: "black"
									}),
									menuList: (baseStyles, state) => ({
										...baseStyles,
										color: "black"
									}),
								}}
							/>
							{
								primaryDetails.escalation_issues.includes("Others") && (
									<Col xs={12} className="mt-2">
										<Form.Control
											name="escalation_issues_details"
											type="text"
											value={primaryDetails.escalation_issues_details}
											// onChange={handleDetailsChange}
											disabled
										/>
									</Col>
								)
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Escalation (Issues Encountered) 
								{/* <span className="required-badge">*</span> */}
							</Form.Label>
							<Row>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 1"
										value="escalation_issues_1"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_1}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 2"
										value="escalation_issues_2"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_2}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 3"
										value="escalation_issues_3"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_3}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 4"
										value="escalation_issues_4"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_4}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 5"
										value="escalation_issues_5"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_5}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 6"
										value="escalation_issues_6"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_6}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 7"
										value="escalation_issues_7"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_7}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 8"
										value="escalation_issues_8"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_8}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 9"
										value="escalation_issues_9"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_9}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Choice 10"
										value="escalation_issues_10"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_10}
										disabled
									/>
								</Col>
								<Col xs={12}>
									<Form.Check
										inline
										label="Others"
										value="escalation_issues_other"
										name="escalation_issues"
										type="checkbox"
										checked={primaryDetails.escalation_issues_other}
										disabled
									/>
								</Col>
								{
									primaryDetails.escalation_issues_other && (
										<Col xs={12}>
											<Form.Control
												name="escalation_issues_details"
												type="text"
												value={primaryDetails.escalation_issues_details}
												disabled
											/>
										</Col>
									)
								}
							</Row>
						</Form.Group>
						<Form.Group className="mb-3" controlId="typeOfStore">
							<Form.Label className="font-med">
								Escalation (Reported to Maya) {" "}
								{/* <span className="font-regular font-10">For Merchants with Non-Maya SQR</span> */}
							</Form.Label>
							{
								["radio"].map((type) => (
								<div key={`inline-qr_payments-${type}`} className="mb-3">
								<>
										<Form.Check
											inline
											label="Yes"
											value="1"
											name="escalation_is_reported"
											type={type}
											id={`inline-escalation_is_reported-${type}-2`}
											defaultChecked={primaryDetails.escalation_is_reported === "1"}
											disabled
										/>
										<Form.Check
											inline
											label="No"
											value="0"
											name="escalation_is_reported"
											type={type}
											id={`inline-escalation_is_reported-${type}-3`}
											defaultChecked={primaryDetails.escalation_is_reported === "0"}
											disabled
										/>
								</>
								</div>
							))}
						</Form.Group>
						</>
					)
				}
				
				<Form.Group className="mb-3">
					<Form.Label className="font-med">
						Other Competitor or Store Activities
					</Form.Label>
					<Form.Control
						name="other_competitor_details"
						type="text"
						value={primaryDetails.other_competitor_details}
						disabled
					/>
				</Form.Group>
				{primaryDetails.file_names.length !== 0 && (
				<>
					<Form.Group className="mt-1">
						<Form.Label className="font-med">
							Other Competitor or Store Activities Attachment:
						</Form.Label>
					</Form.Group>
				</>
				)}
				{
					primaryDetails.file_names.map((file, i) => {
						return (
							<>
							<Form.Group className="">
								<Form.Label className="font-med">
									{file}
								</Form.Label>
							</Form.Group>
							</>
						);
					})
				}
				
				<Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
					<Button
					variant="primary"
					size="md"
					className="full-width-btn confirm-btn logout"
					onClick={handleBack}
					>
					Back
					</Button>
				</Form.Group>
				<Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
					{loading ? (
					<Row className="justify-content-md-center">
						<Col md={1} sm={1} xs={1} className="left-38">
						<ReactLoading
							type="spinningBubbles"
							color="#87BD40"
							height={100}
							width={50}
						/>
						</Col>
					</Row>
					) : (
					<Button
						variant="primary"
						size="md"
						className="full-width-btn confirm-btn"
						onClick={handleSubmit}
					>
						Confirm & Submit
					</Button>
					)}
				</Form.Group>
			</Form>
      	</Row>
      	{/* <img src={swoosh} alt="logo" height={250} className={"swoosh"} /> */}
		<Modal
			show={openModal}
			onHide={() => setOpenModal(false)}
			backdrop="static"
			keyboard={false}
			size="md"
			centered
		>
			<Modal.Header>
				<Row style={{ display: "contents" }}>
					<span className="title" style={{ textAlign: "center" }}>
					INFORMATION SUCCESSFULLY SAVED!
					</span>
				</Row>
			</Modal.Header>
			<Modal.Body className="action">
				<Form>
					<Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
						<Button
							variant="primary"
							size="md"
							className="full-width-btn confirm-btn"
							onClick={handleAnotherRegistration}
						>
							Register Another Customer
						</Button>
					</Form.Group>
					<Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
						<Button
							variant="primary"
							size="md"
							className="full-width-btn confirm-btn logout "
							onClick={handleLogout}
						>
							Logout
						</Button>
					</Form.Group>
				</Form>
			</Modal.Body>
		</Modal>
		<Modal
			show={showBack}
			onHide={() => setShowBack(false)}
			backdrop="static"
			keyboard={false}
			size="md"
			centered
		>
			<Modal.Header>
				<Row className="text-center">
					<span className="title" style={{ color: "#d00000" }}>
					ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?
					</span>
				</Row>
			</Modal.Header>
			<Modal.Body className="action">
				<Row>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Button
							variant="primary"
							size="md"
							className=" confirm-btn full-width-btn"
							onClick={() => navigation.previous()}
						>
							YES
						</Button>
					</Col>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Button
							variant="primary"
							size="md"
							className="full-width-btn confirm-btn logout"
							onClick={() => setShowBack(false)}
						>
							NO
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
    </Container>
  );
}

export default Summary;
