import { getUserType } from "../Utils/Common"
import { handleValidationChange } from "./CommonValidation"

export const validatePrimaryDetailsVer2 = (data, sqrTerminals, setIsError) => {
  var isValid = true
  var hasSelected = false

  sqrTerminals.map((data) => {
    if (data.selected === true) {
      hasSelected = true
    }
  })

  if (hasSelected === false) {
    handleValidationChange("sqr_terminal", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("sqr_terminal", false, setIsError)
  }

  if (
    data.merchant_id === "" ||
    data.merchant_id === undefined ||
    data.merchant_id === null
  ) {
    handleValidationChange("merchant_id", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_id", false, setIsError)
  }

  if (
    data.merchant_name === "" ||
    data.merchant_name === undefined ||
    data.merchant_name === null ||
    data.merchant_name.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("merchant_name", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_name", false, setIsError)
  }

  if (
    data.outlet_id === "" ||
    data.outlet_id === undefined ||
    data.outlet_id === null
  ) {
    handleValidationChange("outlet_id", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("outlet_id", false, setIsError)
  }

  if (
    data.outlet === "" ||
    data.outlet === undefined ||
    data.outlet === null ||
    data.outlet.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("outlet", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("outlet", false, setIsError)
  }

  if (
    data.city === "" ||
    data.city === undefined ||
    data.city === null ||
    data.city.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("city", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("city", false, setIsError)
  }

  if (
    data.brgy === "" ||
    data.brgy === undefined ||
    data.brgy === null ||
    data.brgy.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("brgy", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("brgy", false, setIsError)
  }

  if (
    data.postal_code === "" ||
    data.postal_code === undefined ||
    data.postal_code === null ||
    data.postal_code.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("postal_code", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("postal_code", false, setIsError)
  }

  if (
    data.floor_no === "" ||
    data.floor_no === undefined ||
    data.floor_no === null ||
    data.floor_no.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("floor_no", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("floor_no", false, setIsError)
  }

  if (
    data.building === "" ||
    data.building === undefined ||
    data.building === null ||
    data.building.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("building", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("building", false, setIsError)
  }

  if (
    data.coordinates === "" ||
    data.coordinates === undefined ||
    data.coordinates === null ||
    data.coordinates.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("coordinates", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("coordinates", false, setIsError)
  }

  if (
    data.maya_merchant === "" ||
    data.maya_merchant === undefined ||
    data.maya_merchant === null
  ) {
    handleValidationChange("maya_merchant", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("maya_merchant", false, setIsError)
  }

  if (
    data.maya_merchant === "1" &&
    (data.merchant_mid_sqr === "" ||
      data.merchant_mid_sqr === undefined ||
      data.merchant_mid_sqr === null ||
      data.merchant_mid_sqr.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("merchant_mid_sqr", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_mid_sqr", false, setIsError)
  }

  if (
    data.maya_merchant === "1" &&
    (data.merchant_mid_terminal === "" ||
      data.merchant_mid_terminal === undefined ||
      data.merchant_mid_terminal === null ||
      data.merchant_mid_terminal.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("merchant_mid_terminal", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_mid_terminal", false, setIsError)
  }

  if (
    data.maya_merchant === "1" &&
    ((data.sn_terminal === "" && data.store_code === "") ||
      data.store_code.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("store_code", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("store_code", false, setIsError)
  }

  if (
    data.name === "" ||
    data.name === undefined ||
    data.name === null ||
    data.name.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("name", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("name", false, setIsError)
  }

  if (
    data.number === "" ||
    data.number === undefined ||
    data.number === null ||
    data.number.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("number", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("number", false, setIsError)
  }

  if (
    data.email === "" ||
    data.email === undefined ||
    data.email === null ||
    data.email.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("email", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("email", false, setIsError)
  }

  if (
    data.maya_solutions === "" ||
    data.maya_solutions === undefined ||
    data.maya_solutions === null
  ) {
    handleValidationChange("maya_solutions", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("maya_solutions", false, setIsError)
  }

  // if (!data.terminal_gcash && !data.terminal_BDO && !data.terminal_shopee && !data.terminal_alipay && !data.terminal_goTyme
  //     && !data.terminal_union_pay && !data.terminal_atome && !data.terminal_grabPay && !data.terminal_bookyPay
  //         && !data.terminal_hello_money && !data.terminal_weChat && !data.terminal_plentina && !data.terminal_other && !data.terminal_none) {
  //     handleValidationChange("available_terminal", true, setIsError);
  //     isValid=false;
  // } else {
  //     handleValidationChange("available_terminal", false, setIsError);
  // }

  // if (data.terminal_other === true && (data.available_terminal_other_details === "" || data.available_terminal_other_details === undefined || data.available_terminal_other_details.replace(/\s/g, "").length === 0)) {
  //     handleValidationChange("available_terminal_other_details", true, setIsError);
  //     isValid=false;
  // } else {
  //     handleValidationChange("available_terminal_other_details", false, setIsError);
  // }

  if (
    !data.competitor_solutions_Cash &&
    !data.competitor_solutions_BDO &&
    !data.competitor_solutions_metrobank &&
    !data.competitor_solutions_unionbank &&
    !data.competitor_solutions_global_payments &&
    !data.competitor_solutions_HSBC &&
    !data.competitor_solutions_maybank &&
    !data.competitor_solutions_RCBC &&
    !data.competitor_solutions_stripe_payments &&
    !data.competitor_solutions_GHL &&
    !data.competitor_solutions_other &&
    !data.competitor_solutions_none
  ) {
    handleValidationChange("solution_device", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("solution_device", false, setIsError)
  }

  if (
    data.competitor_solutions_other === true &&
    (data.solution_device_other_details === "" ||
      data.solution_device_other_details === undefined ||
      data.solution_device_other_details.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("solution_device_other_details", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("solution_device_other_details", false, setIsError)
  }

  if (
    data.maya_transaction === "" ||
    data.maya_transaction === undefined ||
    data.maya_transaction === null ||
    data.maya_transaction.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("maya_transaction", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("maya_transaction", false, setIsError)
  }

  if (
    data.gcash_transaction === "" ||
    data.gcash_transaction === undefined ||
    data.gcash_transaction === null ||
    data.gcash_transaction.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("gcash_transaction", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("gcash_transaction", false, setIsError)
  }

  if (
    data.cash_transaction === "" ||
    data.cash_transaction === undefined ||
    data.cash_transaction === null ||
    data.cash_transaction.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("cash_transaction", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("cash_transaction", false, setIsError)
  }

  if (
    data.other_transaction_details === "" ||
    data.other_transaction_details === undefined ||
    data.other_transaction_details === null ||
    data.other_transaction_details.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("other_transaction_details", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("other_transaction_details", false, setIsError)
  }

  if (
    data.other_transaction === "" ||
    data.other_transaction === undefined ||
    data.other_transaction === null ||
    data.other_transaction.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("other_transaction", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("other_transaction", false, setIsError)
  }

  if (
    !data.merchandising_material_BDO &&
    !data.merchandising_material_gcash &&
    !data.merchandising_material_shopee &&
    !data.merchandising_material_atome &&
    !data.merchandising_material_other &&
    !data.merchandising_material_none
  ) {
    handleValidationChange("own_merchandising", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("own_merchandising", false, setIsError)
  }

  if (
    data.merchandising_material_other === true &&
    (data.own_merchandising_other_details === "" ||
      data.own_merchandising_other_details === undefined ||
      data.own_merchandising_other_details.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("own_merchandising_other_details", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("own_merchandising_other_details", false, setIsError)
  }

  if (
    data.qrph_known === "" ||
    data.qrph_known === undefined ||
    data.qrph_known === null
  ) {
    handleValidationChange("qrph_known", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("qrph_known", false, setIsError)
  }

  if (
    data.merchandising_restrictions === "" ||
    data.merchandising_restrictions === undefined ||
    data.merchandising_restrictions === null
  ) {
    handleValidationChange("merchandising_restrictions", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchandising_restrictions", false, setIsError)
  }

  if (
    !data.merchant_concern_competitor_priority &&
    !data.merchant_concern_closed_store &&
    !data.merchant_concern_pullout &&
    !data.merchant_concern_pending_pos_or_qr &&
    !data.merchant_concern_account_issue &&
    !data.merchant_concern_high_cash_payment &&
    !data.merchant_concern_low_foot_traffic &&
    !data.merchant_concern_update_name &&
    !data.merchant_concern_change_tagging &&
    !data.merchant_concern_maya_manager_issue &&
    !data.merchant_concern_other &&
    !data.merchant_concern_no_issue
  ) {
    handleValidationChange("merchant_concern", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_concern", false, setIsError)
  }

  if (
    data.merchant_concern_other === true &&
    (data.merchant_concern_other_details === "" ||
      data.merchant_concern_other_details === undefined ||
      data.merchant_concern_other_details.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("merchant_concern_other_details", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchant_concern_other_details", false, setIsError)
  }

  if (
    !data.servicing_poor_connection &&
    !data.servicing_settlement_issue &&
    !data.servicing_thermal_rolls_request &&
    !data.servicing_transaction_failed &&
    !data.servicing_reversal_transaction &&
    !data.servicing_no_receipt &&
    !data.servicing_print_problems &&
    !data.servicing_terminal_replacement &&
    !data.servicing_charger_replacement &&
    !data.servicing_simcard_replacement &&
    !data.servicing_other &&
    !data.servicing_no_issue
  ) {
    handleValidationChange("servicing", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("servicing", false, setIsError)
  }

  if (
    data.servicing_other === true &&
    (data.servicing_other_details === "" ||
      data.servicing_other_details === undefined ||
      data.servicing_other_details.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange("servicing_other_details", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("servicing_other_details", false, setIsError)
  }

  // if (!data.replacement_terminal_replacement && !data.replacement_charger_replacement && !data.replacement_simcard_replacement
  //     && !data.replacement_no_issue) {
  //         handleValidationChange("replacement", true, setIsError);
  //         isValid=false;
  // } else {
  //     handleValidationChange("replacement", false, setIsError);
  // }

  if (
    !data.merchandising_request_standee &&
    !data.merchandising_request_door_hanger &&
    !data.merchandising_request_billfolds &&
    !data.merchandising_request_ballpen &&
    !data.merchandising_request_tarpaulin &&
    !data.merchandising_request_other &&
    !data.merchandising_request_none
  ) {
    handleValidationChange("merchandising_request", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("merchandising_request", false, setIsError)
  }

  if (
    data.merchandising_request_other === true &&
    (data.merchandising_request_other_details === "" ||
      data.merchandising_request_other_details === undefined ||
      data.merchandising_request_other_details.replace(/\s/g, "").length === 0)
  ) {
    handleValidationChange(
      "merchandising_request_other_details",
      true,
      setIsError
    )
    isValid = false
  } else {
    handleValidationChange(
      "merchandising_request_other_details",
      false,
      setIsError
    )
  }

  if (
    data.retraining_concern === "" ||
    data.retraining_concern === undefined ||
    data.retraining_concern === null
  ) {
    handleValidationChange("retraining_concern", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("retraining_concern", false, setIsError)
  }

  if (
    data.is_resolved === "" ||
    data.is_resolved === undefined ||
    data.is_resolved === null
  ) {
    handleValidationChange("is_resolved", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("is_resolved", false, setIsError)
  }

  if (
    data.escalation === "" ||
    data.escalation === undefined ||
    data.escalation === null ||
    data.escalation.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("escalation", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("escalation", false, setIsError)
  }

  return isValid
}

export const validateDetails = (data, productCodes, setIsError) => {
  var type = getUserType()
  var isValid = true
  var isValidName = true
  var isValidContact = true
  var isValidEmail = true
  var isValidOR = true
  var isValidPatientType = true
  var isValidLCNCP = true
  var isValidEUCG = true
  var isValidBumo = true
  var isValidProductCodes = false
  var isValidTimeCall = true
  var isValidCallTime = true
  var isValidDiabetasolDiagnosis = true
  var isValidEntrasolDiagnosis = true
  var isValidDiabetasolSweetenerDiagnosis = true
  var isValidConsumerEntry = true

  if (data.is_diabetasol === "1") {
    if (
      data.diabetasol_diagnosis === "" ||
      data.diabetasol_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange("diabetasol_diagnosis", true, setIsError)
      isValidDiabetasolDiagnosis = false
    } else {
      handleValidationChange("diabetasol_diagnosis", false, setIsError)
      isValidDiabetasolDiagnosis = true
    }
  } else {
    handleValidationChange("diabetasol_diagnosis", false, setIsError)
    isValidDiabetasolDiagnosis = true
  }

  if (data.is_entrasol === "1") {
    if (
      data.entrasol_diagnosis === "" ||
      data.entrasol_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange("entrasol_diagnosis", true, setIsError)
      isValidEntrasolDiagnosis = false
    } else {
      handleValidationChange("entrasol_diagnosis", false, setIsError)
      isValidEntrasolDiagnosis = true
    }
  } else {
    handleValidationChange("entrasol_diagnosis", false, setIsError)
    isValidEntrasolDiagnosis = true
  }

  if (data.is_diabetasol_sweetener === "1") {
    if (
      data.diabetasol_sweetener_diagnosis === "" ||
      data.diabetasol_sweetener_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange("diabetasol_sweetener_diagnosis", true, setIsError)
      isValidDiabetasolSweetenerDiagnosis = false
    } else {
      handleValidationChange(
        "diabetasol_sweetener_diagnosis",
        false,
        setIsError
      )
      isValidDiabetasolSweetenerDiagnosis = true
    }
  } else {
    handleValidationChange("diabetasol_sweetener_diagnosis", false, setIsError)
    isValidDiabetasolSweetenerDiagnosis = true
  }

  if (data.name === "" || data.name.replace(/\s/g, "").length === 0) {
    handleValidationChange("name", true, setIsError)
    isValidName = false
  } else {
    handleValidationChange("name", false, setIsError)
    isValidName = true
  }

  if (data.contact_no === "") {
    handleValidationChange("contact_no", true, setIsError)
    isValidContact = false
  } else {
    handleValidationChange("contact_no", false, setIsError)
    isValidContact = true
  }

  if (data.call_time === "" || data.call_time === "Select...") {
    handleValidationChange("call_time", true, setIsError)
    isValidCallTime = false
  } else {
    handleValidationChange("call_time", false, setIsError)
    isValidCallTime = true
  }

  if (type === "patient") {
    if (data.patient_type === "") {
      handleValidationChange("patient_type", true, setIsError)
      isValidPatientType = false
    } else {
      handleValidationChange("patient_type", false, setIsError)
      isValidPatientType = true
    }
  }

  if (type === "consumer") {
    if (data.bumo === "") {
      handleValidationChange("bumo", true, setIsError)
      isValidBumo = false
    } else {
      handleValidationChange("bumo", false, setIsError)
      isValidBumo = true
    }
  }

  if (data.lc_nc_p === "") {
    handleValidationChange("lc_nc_p", true, setIsError)
    isValidLCNCP = false
  } else {
    handleValidationChange("lc_nc_p", false, setIsError)
    isValidLCNCP = true
  }

  if (data.eu_cg === "") {
    handleValidationChange("eu_cg", true, setIsError)
    isValidEUCG = false
  } else {
    handleValidationChange("eu_cg", false, setIsError)
    isValidEUCG = true
  }

  if (
    data.is_diabetasol === "0" &&
    data.is_entrasol === "0" &&
    data.is_diabetasol_sweetener === "0"
  ) {
    handleValidationChange("consumer_entry", true, setIsError)
    isValidConsumerEntry = false
  } else {
    handleValidationChange("consumer_entry", false, setIsError)
    isValidConsumerEntry = true
  }

  isValid =
    isValid &&
    isValidName &&
    isValidContact &&
    isValidOR &&
    isValidPatientType &&
    isValidBumo &&
    isValidLCNCP &&
    isValidEUCG &&
    isValidCallTime &&
    isValidDiabetasolDiagnosis &&
    isValidDiabetasolSweetenerDiagnosis &&
    isValidEntrasolDiagnosis &&
    isValidConsumerEntry

  return isValid
}
