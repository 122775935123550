import React, { useEffect, useState } from "react"
import { Button, Container, Form, Row, Col, Modal } from "react-bootstrap"
import "./FormStyles.css"
import { ToastContainer, toast } from "react-toastify"
import {
  getStreetAddress,
  getUser,
  getUserType,
} from "../../Helpers/Utils/Common"
import swoosh from "../../Assets/Logo/swoosh.png"
import {
  validateClaasificationDetails,
  validatePrimaryDetails,
} from "../../Helpers/Validation/FormValidation"
import InputError from "../../Components/InputError/InputError"
import greenbird from "../../Assets/Form/maya_greenbird.jpeg"
import version2 from "../../Assets/Form/maya_ver_2.jpeg"
import customized from "../../Assets/Form/maya_customized.jpeg"
import qrph from "../../Assets/Form/maya_qrph.jpeg"
import qrpayment from "../../Assets/Form/maya_qr_payment.jpeg"
import Compressor from "compressorjs"
import Select from "react-select"
import { validatePrimaryDetailsVer2 } from "../../Helpers/Validation/FormValidationVer2"
import { getMerchant } from "../../Helpers/API/formsAPI"

function RegistrationVer2({
  navigation,
  details,
  setDetails,
  primaryDetails,
  setPrimaryDetails,
  classificationDetails,
  setClassificationDetails,
  accounts,
  setAccounts,
  malls,
  setMalls,
  customers,
  setCustomers,
  issues,
  outlets,
  selectedEscalationIssues,
  setSelectedEscalationIssues,
  sqrTerminals,
  setSqrTerminals,
  merchandisingMaterials,
  setMerchandisingMaterials,
}) {
  const user_type = getUserType()
  const [isError, setIsError] = useState({
    merchant_id: false,
    merchant_name: false,
    outlet_id: false,
    outlet: false,
    city: false,
    brgy: false,
    postal_code: false,
    floor_no: false,
    building: false,
    coordinates: false,
    merchant_mid: false,
    store_code: false,
    name: false,
    number: false,
    email: false,
    maya_solutions: false,
    available_terminal: false,
    available_terminal_other_details: false,
    solution_device: false,
    solution_device_other_details: false,
    own_merchandising: false,
    own_merchandising_other_details: false,
    qrph_known: false,
    merchandising_restrictions: false,
    merchant_concern: false,
    merchant_concern_other_details: false,
    servicing: false,
    servicing_other_details: false,
    replacement: false,
    merchandising_request: false,
    merchandising_request_other_details: false,
    retraining_concern: false,
    is_resolved: false,
    escalation: false,
    sqr_terminal: false,
  })

  const [showMaximum, setShowMaximum] = useState(false)
  const [fileNames, setFileNames] = useState([])

  // FUNCTIONS TO STORE AND FORMAT CHECKBOX CHECKED ITEMS TO ONE VARIABLE
  function useUpdateAvailableTerminalAvailable(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.terminal_none) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("terminal_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["terminal_none"]: true,
          ["available_terminal_other_details"]: "",
          ["available_terminal"]: "None",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) => key.startsWith("terminal_") && value === true
          )
          .map(([key]) => key.replace("terminal_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails =
            primaryDetails.available_terminal_other_details || ""
          formattedItems.push(`Others`)
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          available_terminal: selectedItems,
        }))
      }
    }, [
      primaryDetails.terminal_gcash,
      primaryDetails.terminal_BDO,
      primaryDetails.terminal_shopee,
      primaryDetails.terminal_alipay,
      primaryDetails.terminal_goTyme,
      primaryDetails.terminal_union_pay,
      primaryDetails.terminal_atome,
      primaryDetails.terminal_grabPay,
      primaryDetails.terminal_bookyPay,
      primaryDetails.terminal_hello_money,
      primaryDetails.terminal_weChat,
      primaryDetails.terminal_plentina,
      primaryDetails.terminal_other,
      primaryDetails.available_terminal_other_details,
      primaryDetails.terminal_none,
    ])
  }
  useUpdateAvailableTerminalAvailable(primaryDetails)

  function useUpdateCompetitorSolutions(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.competitor_solutions_none) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("competitor_solutions_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["competitor_solutions_none"]: true,
          ["solution_device_other_details"]: "",
          ["solution_device"]: "None",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) =>
              key.startsWith("competitor_solutions_") && value === true
          )
          .map(([key]) => key.replace("competitor_solutions_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails =
            primaryDetails.solution_device_other_details || ""
          formattedItems.push(`Others`)
          // formattedItems.push(`Others: ${otherDetails}`);
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          solution_device: selectedItems,
        }))
      }
    }, [
      primaryDetails.competitor_solutions_metrobank,
      primaryDetails.competitor_solutions_Cash,
      primaryDetails.competitor_solutions_BDO,
      primaryDetails.competitor_solutions_unionbank,
      primaryDetails.competitor_solutions_global_payments,
      primaryDetails.competitor_solutions_HSBC,
      primaryDetails.competitor_solutions_maybank,
      primaryDetails.competitor_solutions_RCBC,
      primaryDetails.competitor_solutions_stripe_payments,
      primaryDetails.competitor_solutions_GHL,
      primaryDetails.competitor_solutions_other,
      primaryDetails.solution_device_other_details,
      ,
      primaryDetails.competitor_solutions_none,
    ])
  }
  useUpdateCompetitorSolutions(primaryDetails)

  console.log("primaryDetails", primaryDetails)

  function useUpdateMerchandisingMaterial(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.merchandising_material_none) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("merchandising_material_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["merchandising_material_none"]: true,
          ["own_merchandising_other_details"]: "",
          ["own_merchandising"]: "None",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) =>
              key.startsWith("merchandising_material_") && value === true
          )
          .map(([key]) => key.replace("merchandising_material_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails =
            primaryDetails.own_merchandising_other_details || ""
          formattedItems.push(`Others`)
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          own_merchandising: selectedItems,
        }))
      }
    }, [
      primaryDetails.merchandising_material_gcash,
      primaryDetails.merchandising_material_BDO,
      primaryDetails.merchandising_material_shopee,
      primaryDetails.merchandising_material_atome,
      primaryDetails.merchandising_material_other,
      primaryDetails.own_merchandising_other_details,
      primaryDetails.merchandising_material_none,
    ])
  }
  useUpdateMerchandisingMaterial(primaryDetails)

  function useUpdateMerchantConcern(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.merchant_concern_no_issue) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("merchant_concern_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["merchant_concern_no_issue"]: true,
          ["merchant_concern_other_details"]: "",
          ["merchant_concern"]: "No Issue",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) =>
              key.startsWith("merchant_concern_") && value === true
          )
          .map(([key]) => key.replace("merchant_concern_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails =
            primaryDetails.merchant_concern_other_details || ""
          formattedItems.push(`Others`)
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          merchant_concern: selectedItems,
        }))
      }
    }, [
      primaryDetails.merchant_concern_competitor_priority,
      primaryDetails.merchant_concern_closed_store,
      primaryDetails.merchant_concern_pullout,
      primaryDetails.merchant_concern_pending_pos_or_qr,
      primaryDetails.merchant_concern_account_issue,
      primaryDetails.merchant_concern_high_cash_payment,
      primaryDetails.merchant_concern_low_foot_traffic,
      primaryDetails.merchant_concern_update_name,
      primaryDetails.merchant_concern_change_tagging,
      primaryDetails.merchant_concern_maya_manager_issue,
      primaryDetails.merchant_concern_other,
      primaryDetails.merchant_concern_other_details,
      primaryDetails.merchant_concern_no_issue,
    ])
  }
  useUpdateMerchantConcern(primaryDetails)

  function useUpdateServicing(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.servicing_no_issue) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("servicing_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["servicing_no_issue"]: true,
          ["servicing_other_details"]: "",
          ["servicing"]: "No Issue",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) => key.startsWith("servicing_") && value === true
          )
          .map(([key]) => key.replace("servicing_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails = primaryDetails.servicing_other_details || ""
          formattedItems.push(`Others`)
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          servicing: selectedItems,
        }))
      }
    }, [
      primaryDetails.servicing_poor_connection,
      primaryDetails.servicing_settlement_issue,
      primaryDetails.servicing_thermal_rolls_request,
      primaryDetails.servicing_transaction_failed,
      primaryDetails.servicing_reversal_transaction,
      primaryDetails.servicing_no_receipt,
      primaryDetails.servicing_print_problems,
      primaryDetails.servicing_terminal_replacement,
      primaryDetails.servicing_charger_replacement,
      primaryDetails.servicing_simcard_replacement,
      primaryDetails.servicing_other,
      primaryDetails.servicing_other_details,
      primaryDetails.servicing_no_issue,
    ])
  }
  useUpdateServicing(primaryDetails)

  function useUpdateReplacement(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.replacement_no_issue) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("replacement_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["replacement_no_issue"]: true,
          ["replacement_other_details"]: "",
          ["replacement"]: "No Issue",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) => key.startsWith("replacement_") && value === true
          )
          .map(([key]) => key.replace("replacement_", ""))

        const selectedItems = checkedValues
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          replacement: selectedItems,
        }))
      }
    }, [
      primaryDetails.replacement_terminal_replacement,
      primaryDetails.replacement_charger_replacement,
      primaryDetails.replacement_simcard_replacement,
      primaryDetails.replacement_no_issue,
    ])
  }
  useUpdateReplacement(primaryDetails)

  function useUpdateMerchandisingRequest(primaryDetails) {
    useEffect(() => {
      if (primaryDetails.merchandising_request_none) {
        const updatedDetails = { ...primaryDetails }
        Object.keys(updatedDetails).forEach((key) => {
          if (key.startsWith("merchandising_request_")) {
            updatedDetails[key] = false
          }
        })
        setPrimaryDetails({
          ...updatedDetails,
          ["merchandising_request_none"]: true,
          ["merchandising_request_other_details"]: "",
          ["merchandising_request"]: "None",
        })
      } else {
        const checkedValues = Object.entries(primaryDetails)
          .filter(
            ([key, value]) =>
              key.startsWith("merchandising_request_") && value === true
          )
          .map(([key]) => key.replace("merchandising_request_", ""))

        let formattedItems = checkedValues
        if (formattedItems.includes("other")) {
          formattedItems = formattedItems.filter((item) => item !== "other")
          const otherDetails =
            primaryDetails.merchandising_request_other_details || ""
          formattedItems.push(`Others`)
        }

        const selectedItems = formattedItems
          .map((item) => {
            const words = item.split("_")
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
          })
          .join(",")
        setPrimaryDetails((prevState) => ({
          ...prevState,
          merchandising_request: selectedItems,
        }))
      }
    }, [
      primaryDetails.merchandising_request_standee,
      primaryDetails.merchandising_request_door_hanger,
      primaryDetails.merchandising_request_billfolds,
      primaryDetails.merchandising_request_ballpen,
      primaryDetails.merchandising_request_tarpaulin,
      primaryDetails.merchandising_request_other,
      primaryDetails.merchandising_request_other_details,
      primaryDetails.merchandising_request_none,
    ])
  }
  useUpdateMerchandisingRequest(primaryDetails)

  function handleDetailsChange(e, selectComponent) {
    if (selectComponent) {
      const { name, value, label, var_name } = e

      if (name === "merchant_id") {
        if (label === "Others") {
          setPrimaryDetails({
            ...primaryDetails,
            [name]: value,
            [var_name]: "",
          })
        } else {
          setPrimaryDetails({
            ...primaryDetails,
            [name]: value,
            [var_name]: label,
          })
          fetchMerchantDetails(value)
        }
      } else {
        if (label === "Others") {
          setPrimaryDetails({
            ...primaryDetails,
            [name]: value,
            [var_name]: "",
            selected_outlet: outlets.filter((data) => data.value === "Others"),
          })
        } else {
          setPrimaryDetails({
            ...primaryDetails,
            [name]: value,
            [var_name]: label,
            selected_outlet: outlets.filter((data) => data.id === value),
          })
        }
      }
    } else {
      const { name, value } = e.target
      if (
        name === "available_terminal" ||
        name === "solution_device" ||
        name === "own_merchandising" ||
        name === "merchant_concern" ||
        name === "servicing" ||
        name === "merchandising_request" ||
        name === "replacement"
      ) {
        if (value === "terminal_other" && !e.target.checked) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["available_terminal_other_details"]: "",
          })
        } else if (
          value === "competitor_solutions_other" &&
          !e.target.checked
        ) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["solution_device_other_details"]: "",
          })
        } else if (
          value === "merchandising_material_other" &&
          !e.target.checked
        ) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["own_merchandising_other_details"]: "",
          })
        } else if (value === "merchant_concern_other" && !e.target.checked) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["merchant_concern_other_details"]: "",
          })
        } else if (value === "servicing_other" && !e.target.checked) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["servicing_other_details"]: "",
          })
        } else if (
          value === "merchandising_request_other" &&
          !e.target.checked
        ) {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
            ["merchandising_request_other_details"]: "",
          })
        } else {
          setPrimaryDetails({
            ...primaryDetails,
            [value]: e.target.checked,
          })
        }
      } else if (name === "maya_merchant" && value === "0") {
        setPrimaryDetails({
          ...primaryDetails,
          [name]: value,
          ["merchant_mid_sqr"]: "",
          ["merchant_mid_terminal"]: "",
          ["store_code"]: "",
          ["sn_terminal"]: "",
        })
      } else if (name === "customer_id" && value === "Others") {
        setPrimaryDetails({
          ...primaryDetails,
          [name]: value,
          ["full_name"]: "",
          ["customer_id_value"]: value,
        })
      } else if (name === "contact_number") {
        const numericValue = value.replace(/[^0-9]/g, "")
        const limitedValue = numericValue.slice(0, 11)
        setPrimaryDetails({ ...primaryDetails, [name]: limitedValue })
      } else {
        setPrimaryDetails({ ...primaryDetails, [name]: value })
      }
    }
  }

  //handle selection of sqr terminal
  function handleSQRChange(key) {
    let sqr_copy = [...sqrTerminals]
    sqr_copy[parseInt(key)]["selected"] = !sqr_copy[parseInt(key)]["selected"]
    setSqrTerminals(sqr_copy)
  }

  //handle selection of other merch mats table
  function handleOtherMerchChange(e, key) {
    const { name } = e.target
    let merch_mat = [...merchandisingMaterials]
    merch_mat[parseInt(key)][name] = !merch_mat[parseInt(key)][name]
    setMerchandisingMaterials(merch_mat)
  }

  function handleSubmit() {
    if (validatePrimaryDetailsVer2(primaryDetails, sqrTerminals, setIsError)) {
      navigation.next()
    }
  }

  async function fetchMerchantDetails(value) {
    const response = await getMerchant(value)
    if (response.data) {
      let data = response.data.data
      setPrimaryDetails({
        ...primaryDetails,
        ...data,
        floor_no: data.floor,
        building: data.bldg,
        maya_merchant: data.is_maya_merchant,
        merchant_mid_terminal: data.maya_merchant_terminal,
        merchant_mid_sqr: data.maya_merchant_sqr,
        store_code: data.sqr_store_code,
        sn_terminal: data.terminal_sn,
        merchant_name: data.name,
        merchant_id: data.id,
        selected_outlet: outlets.filter((val) => val.id === data.outlet)[0],
        outlet_id: data.outlet,
        name: "",
      })
    }
  }
  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-md-center p-5 mt-10">
        <Form.Label className="font-bold">Registration Form </Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="typeOfStore">
            <Form.Label className="font-med">
              Merchant Name <span className="required-badge">*</span>
            </Form.Label>
            <Select
              name="merchant_name"
              className="nc-modal-custom-text"
              options={issues}
              // value={selectedEscalationIssues}
              onChange={(e) => handleDetailsChange(e, true)}
              placeholder={`Select Merchant`}
              // isMulti={true}
              isSearchable={true}
              styles={{
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
              }}
            />
            {primaryDetails.merchant_id === "Others" && (
              <Col xs={12} className="mt-2">
                <Form.Control
                  name="merchant_name"
                  type="text"
                  value={primaryDetails.merchant_name}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.merchant_name}
                  message={"This Field is Required*."}
                />
              </Col>
            )}
            <InputError
              isValid={isError.merchant_id}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="dbaOutlet">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  DBA + Outlet <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Example: ADIDAS GLORIETTA 2</span>
							</Col> */}
              </Row>
            </Form.Label>

            <Select
              name="outlet"
              className="nc-modal-custom-text"
              options={outlets}
              value={primaryDetails.selected_outlet}
              onChange={(e) => handleDetailsChange(e, true)}
              placeholder={`Select Outlet`}
              // isMulti={true}
              isSearchable={true}
              styles={{
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
              }}
            />
            {primaryDetails.outlet_id === "Others" && (
              <Col xs={12} className="mt-2">
                <Form.Control
                  name="outlet"
                  type="text"
                  value={primaryDetails.outlet}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.outlet}
                  message={"This Field is Required*."}
                />
              </Col>
            )}
            <InputError
              isValid={isError.outlet_id}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label className="font-med">
              City <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={primaryDetails.city}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.city}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="brgy">
            <Form.Label className="font-med">
              Brgy <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="brgy"
              type="text"
              value={primaryDetails.brgy}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.brgy}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="postal_code">
            <Form.Label className="font-med">
              Postal Code <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="postal_code"
              type="text"
              value={primaryDetails.postal_code}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.postal_code}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="floor_no">
            <Form.Label className="font-med">
              Slot/Floor <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="floor_no"
              type="text"
              value={primaryDetails.floor_no}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.floor_no}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="building">
            <Form.Label className="font-med">
              Building <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="building"
              type="text"
              value={primaryDetails.building}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.building}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="location">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Coordinates <span className="required-badge">* </span>
                </Col>
                <Col xs={12}>
                  {/* <span className="font-regular font-10">How to get coordinates?</span> */}
                  <a
                    className="add-supplier-label font-regular font-10"
                    href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop"
                    target="_blank"
                  >
                    How to get coordinates?
                  </a>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="coordinates"
              type="text"
              value={primaryDetails.coordinates}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.coordinates}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="maya_merchant">
            <Form.Label className="font-med ">
              Maya Merchant <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-maya_merchant-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="maya_merchant"
                    type={type}
                    id={`inline-maya_merchant-${type}-2`}
                    checked={primaryDetails.maya_merchant === "1"}
                    onClick={handleDetailsChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="maya_merchant"
                    type={type}
                    id={`inline-maya_merchant-${type}-3`}
                    checked={primaryDetails.maya_merchant === "0"}
                    onClick={handleDetailsChange}
                  />
                </>
                <InputError
                  isValid={isError.maya_merchant}
                  message={"This Field is Required*."}
                />
              </div>
            ))}
          </Form.Group>
          {primaryDetails.maya_merchant === "1" && (
            <>
              <Form.Group className="mb-3" controlId="midsqr">
                <Form.Label className="font-med">
                  <Col xs={12}>
                    Maya Merchant MID - SQR{" "}
                    <span className="required-badge">*</span>
                  </Col>
                  <Col xs={12}>
                    <span className="font-regular font-10">
                      If not merchant, type N/A
                    </span>
                  </Col>
                </Form.Label>
                <Form.Control
                  name="merchant_mid_sqr"
                  type="text"
                  value={primaryDetails.merchant_mid_sqr}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.merchant_mid_sqr}
                  message={"This Field is Required*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="midterminal">
                <Form.Label className="font-med">
                  <Col xs={12}>
                    Maya Merchant MID - Terminal{" "}
                    <span className="required-badge">*</span>
                  </Col>
                  <Col xs={12}>
                    <span className="font-regular font-10">
                      If not merchant, type N/A
                    </span>
                  </Col>
                </Form.Label>
                <Form.Control
                  name="merchant_mid_terminal"
                  type="text"
                  value={primaryDetails.merchant_mid_terminal}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.merchant_mid_terminal}
                  message={"This Field is Required*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="sn_terminal">
                <Form.Label className="font-med">
                  Terminal SN
                  {primaryDetails.sn_terminal === "" &&
                    primaryDetails.store_code === "" && (
                      <span className="required-badge">*</span>
                    )}
                  {primaryDetails.sn_terminal !== "" && (
                    <span className="required-badge">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  name="sn_terminal"
                  type="text"
                  value={primaryDetails.sn_terminal}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.store_code}
                  message={"Please add Terminal SN and/or SQR Store Code*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="store_code">
                <Form.Label className="font-med">
                  SQR Store Code
                  {primaryDetails.sn_terminal === "" &&
                    primaryDetails.store_code === "" && (
                      <span className="required-badge">*</span>
                    )}
                  {primaryDetails.store_code !== "" && (
                    <span className="required-badge">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  name="store_code"
                  type="text"
                  value={primaryDetails.store_code}
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.store_code}
                  message={"Please add Terminal SN and/or SQR Store Code*."}
                />
              </Form.Group>
            </>
          )}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="font-med">
              Name <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={primaryDetails.name}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.name}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="number">
            <Form.Label className="font-med">
              Number <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="number"
              type="text"
              maxLength={11}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={primaryDetails.number}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.number}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label className="font-med">
              Email <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="email"
              type="text"
              value={primaryDetails.email}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.email}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="maya_solutions">
            <Form.Label className="font-med">
              Maya Solutions <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-mall_tagging-${type}`} className="mb-3">
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Terminal"
                        value="Terminal"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-3`}
                        // defaultChecked={primaryDetails.maya_solutions === "Terminal"}
                        onClick={handleDetailsChange}
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="SQR"
                        value="SQR"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-4`}
                        // defaultChecked={primaryDetails.maya_solutions === "SQR"}
                        onClick={handleDetailsChange}
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Terminal & SQR"
                        value="Terminal & SQR"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-5`}
                        // defaultChecked={primaryDetails.maya_solutions === "Terminal & SQR"}
                        onClick={handleDetailsChange}
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="Online"
                        value="online"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-7`}
                        defaultChecked={
                          primaryDetails.maya_solutions === "online"
                        }
                        onClick={handleDetailsChange}
                      />
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        inline
                        label="No Solution"
                        value="No Solution"
                        name="maya_solutions"
                        type={type}
                        id={`inline-maya_solutions-${type}-6`}
                        // defaultChecked={primaryDetails.maya_solutions === "No Solution"}
                        onClick={handleDetailsChange}
                      />
                    </Col>
                    {/* <Col xs={12}>
                                    <Form.Check
                                        inline
                                        label="N/A"
                                        value="N/A"
                                        name="maya_solutions"
                                        type={type}
                                        id={`inline-maya_solutions-${type}-7`}
                                        defaultChecked={primaryDetails.maya_solutions === "N/A"}
                                        onClick={handleDetailsChange}
                                    />
                                </Col> */}
                  </Row>
                </>
              </div>
            ))}
            <InputError
              isValid={isError.maya_solutions}
              message={"This Field is Required*."}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Terminal/s Available{" "}
                  <span className="required-badge">* </span>
                </Col>
               
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Gcash"
                  value="terminal_gcash"
                  id="terminal_gcash"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_gcash}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BDO"
                  value="terminal_BDO"
                  id="terminal_BDO"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_BDO}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Shopee"
                  value="terminal_shopee"
                  id="terminal_shopee"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_shopee}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Alipay"
                  value="terminal_alipay"
                  id="terminal_alipay"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_alipay}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="GoTyme"
                  value="terminal_goTyme"
                  id="terminal_goTyme"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_goTyme}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Union Pay"
                  value="terminal_union_pay"
                  id="terminal_union_pay"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_union_pay}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Atome"
                  value="terminal_atome"
                  id="terminal_atome"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_atome}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="GrabPay"
                  value="terminal_grabPay"
                  id="terminal_grabPay"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_grabPay}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BookyPay"
                  value="terminal_bookyPay"
                  id="terminal_bookyPay"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_bookyPay}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Hello Money"
                  value="terminal_hello_money"
                  id="terminal_hello_money"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_hello_money}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="WeChat"
                  value="terminal_weChat"
                  id="terminal_weChat"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_weChat}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Plentina"
                  value="terminal_plentina"
                  id="terminal_plentina"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_plentina}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="terminal_other"
                  id="terminal_other"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.terminal_none}
                />
              </Col>
              {primaryDetails.terminal_other && (
                <Col xs={12}>
                  <Form.Control
                    name="available_terminal_other_details"
                    type="text"
                    value={primaryDetails.available_terminal_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.available_terminal_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="terminal_none"
                  id="terminal_none"
                  name="available_terminal"
                  type="checkbox"
                  checked={primaryDetails.terminal_none}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.available_terminal}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Competitor Solution Device{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Cash"
                  value="competitor_solutions_Cash"
                  id="competitor_solutions_Cash"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_Cash}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BDO"
                  value="competitor_solutions_BDO"
                  id="competitor_solutions_BDO"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_BDO}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Metrobank"
                  value="competitor_solutions_metrobank"
                  id="competitor_solutions_metrobank"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_metrobank}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Unionbank"
                  value="competitor_solutions_unionbank"
                  id="competitor_solutions_unionbank"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_unionbank}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Global Payments"
                  value="competitor_solutions_global_payments"
                  id="competitor_solutions_global_payments"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_global_payments}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="HSBC"
                  value="competitor_solutions_HSBC"
                  id="competitor_solutions_HSBC"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_HSBC}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Maybank"
                  value="competitor_solutions_maybank"
                  id="competitor_solutions_maybank"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_maybank}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="RCBC"
                  value="competitor_solutions_RCBC"
                  id="competitor_solutions_RCBC"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_RCBC}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Stripe Payments"
                  value="competitor_solutions_stripe_payments"
                  id="competitor_solutions_stripe_payments"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_stripe_payments}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="GHL"
                  value="competitor_solutions_GHL"
                  id="competitor_solutions_GHL"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_GHL}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="competitor_solutions_other"
                  id="competitor_solutions_other"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.competitor_solutions_none}
                />
              </Col>
              {primaryDetails.competitor_solutions_other && (
                <Col xs={12}>
                  <Form.Control
                    name="solution_device_other_details"
                    type="text"
                    value={primaryDetails.solution_device_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.solution_device_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="competitor_solutions_none"
                  id="competitor_solutions_none"
                  name="solution_device"
                  type="checkbox"
                  checked={primaryDetails.competitor_solutions_none}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.solution_device}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  SQR/Terminal Available
                  <span className="required-badge">* </span>
                </Col>
              </Row>
            </Form.Label>
            <Row>
              {sqrTerminals.map((data, key) => (
                <Col xs={12}>
                  <Form.Check
                    inline
                    label={data.value}
                    value={data.value}
                    type="checkbox"
                    checked={data.selected}
                    onClick={() => handleSQRChange(key)}
                  />
                </Col>
              ))}

              <InputError
                isValid={isError.sqr_terminal}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
            <Form.Group className="mb-3" controlId="merchandisiong_materials">
            <Form.Label className="font-med">
              Merchandising Materials Available
              
            </Form.Label>
            <div className="row">
              <div className="col-12" sm={12}>
                <table style={{background:"white", color:"#232b2b"}}>
                  <thead style={{fontSize:"x-small", color:"#232b2b"}}>
                    <tr>
                      <th> </th>
                      <th align="center" style={{ textAlign: "-webkit-center" }}>Door Hanger</th>
                      <th align="center" style={{ textAlign: "-webkit-center" }}>QR Standee</th>
                      <th align="center" style={{ textAlign: "-webkit-center" }}>Terminal Standee</th>
                      <th align="center" style={{ textAlign: "-webkit-center" }}>Billfold</th>
                      <th align="center" style={{ textAlign: "-webkit-center" }}>Other Merch Mats</th>
                    </tr>
                  </thead>
                  <tbody>
                    {merchandisingMaterials.map((data, key) => (
                      <tr>
                        <td style={{fontSize:"small"}} align="center">{data.name}</td>
                        <td>
                          {" "}
                          <Form.Check
                            inline
                            name="door_hanger"
                            type="checkbox"
                            onClick={(e) => handleOtherMerchChange(e, key)}
                            checked={data.door_hanger}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="qr_standee"
                            type="checkbox"
                            onClick={(e) => handleOtherMerchChange(e, key)}
                            checked={data.qr_standee}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="terminal_standee"
                            type="checkbox"
                            onClick={(e) => handleOtherMerchChange(e, key)}
                            checked={data.terminal_standee}
                          />
                        </td>
                        <td align="center">
                          <Form.Check
                            inline
                            name="billfold"
                            type="checkbox"
                            onClick={(e) => handleOtherMerchChange(e, key)}
                            checked={data.billfold}
                          />
                        </td>
                        <td align="center" style={{ textAlign: "end" }}>
                          <Form.Check
                            inline
                            name="other_merch_mats"
                            type="checkbox"
                            onClick={(e) => handleOtherMerchChange(e, key)}
                            checked={data.other_merch_mats}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Form.Group>
          <Form.Label className="font-med">
            <Row>
              <Col xs={12}>Out of 10 transactions, how many are:</Col>
              <Col xs={12}>
                <span className="font-regular font-10">
                  (Indicate 0 to skip choice)
                </span>
              </Col>
            </Row>
          </Form.Label>
          <Form.Group className="mb-3" controlId="maya_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Maya <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="maya_transaction"
              type="text"
              value={primaryDetails.maya_transaction}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.maya_transaction}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="gcash_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Gcash <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="gcash_transaction"
              type="text"
              value={primaryDetails.gcash_transaction}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.gcash_transaction}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="cash_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Cash <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="cash_transaction"
              type="text"
              value={primaryDetails.cash_transaction}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.cash_transaction}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="other_transaction">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Others <span className="required-badge">*</span>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              name="other_transaction_details"
              type="text"
              className="mb-2"
              placeholder="Type of payment made"
              value={primaryDetails.other_transaction_details}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.other_transaction_details}
              message={"This Field is Required*."}
            />
            <Form.Control
              name="other_transaction"
              type="text"
              placeholder="How many"
              value={primaryDetails.other_transaction}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.other_transaction}
              message={"This Field is Required*."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Own Merchandising Material{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Gcash"
                  value="merchandising_material_gcash"
                  id="merchandising_material_gcash"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_gcash}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_material_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="BDO"
                  value="merchandising_material_BDO"
                  id="merchandising_material_BDO"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_BDO}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_material_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Shopee"
                  value="merchandising_material_shopee"
                  id="merchandising_material_shopee"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_shopee}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_material_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Atome"
                  value="merchandising_material_atome"
                  id="merchandising_material_atome"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_atome}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_material_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchandising_material_other"
                  id="merchandising_material_other"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_material_none}
                />
              </Col>
              {primaryDetails.merchandising_material_other && (
                <Col xs={12}>
                  <Form.Control
                    name="own_merchandising_other_details"
                    type="text"
                    value={primaryDetails.own_merchandising_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.own_merchandising_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="merchandising_material_none"
                  id="merchandising_material_none"
                  name="own_merchandising"
                  type="checkbox"
                  checked={primaryDetails.merchandising_material_none}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.own_merchandising}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Awareness of QRPh <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-qrph_known-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="qrph_known"
                    type={type}
                    id={`inline-qrph_known-${type}-2`}
                    defaultChecked={primaryDetails.qrph_known === "1"}
                    onClick={handleDetailsChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="qrph_known"
                    type={type}
                    id={`inline-qrph_known-${type}-3`}
                    defaultChecked={primaryDetails.qrph_known === "0"}
                    onClick={handleDetailsChange}
                  />
                </>
                <InputError
                  isValid={isError.qrph_known}
                  message={"This Field is Required*."}
                />
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Merchandising Restrictions{" "}
              <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div
                key={`inline-merchandising_restrictions-${type}`}
                className="mb-3"
              >
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="merchandising_restrictions"
                    type={type}
                    id={`inline-merchandising_restrictions-${type}-2`}
                    defaultChecked={
                      primaryDetails.merchandising_restrictions === "1"
                    }
                    onClick={handleDetailsChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="merchandising_restrictions"
                    type={type}
                    id={`inline-merchandising_restrictions-${type}-3`}
                    defaultChecked={
                      primaryDetails.merchandising_restrictions === "0"
                    }
                    onClick={handleDetailsChange}
                  />
                </>
                <InputError
                  isValid={isError.merchandising_restrictions}
                  message={"This Field is Required*."}
                />
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="merchant_concern">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Merchant Concern <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Competitor Priority"
                  value="merchant_concern_competitor_priority"
                  id="merchant_concern_competitor_priority"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_competitor_priority}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Closed Store"
                  value="merchant_concern_closed_store"
                  id="merchant_concern_closed_store"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_closed_store}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Pullout"
                  value="merchant_concern_pullout"
                  id="merchant_concern_pullout"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_pullout}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Pending POS/QR"
                  value="merchant_concern_pending_pos_or_qr"
                  id="merchant_concern_pending_pos_or_qr"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_pending_pos_or_qr}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Account Issue"
                  value="merchant_concern_account_issue"
                  id="merchant_concern_account_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_account_issue}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="High cash payment"
                  value="merchant_concern_high_cash_payment"
                  id="merchant_concern_high_cash_payment"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_high_cash_payment}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Low foot traffic"
                  value="merchant_concern_low_foot_traffic"
                  id="merchant_concern_low_foot_traffic"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_low_foot_traffic}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Update name"
                  value="merchant_concern_update_name"
                  id="merchant_concern_update_name"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_update_name}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Change tagging"
                  value="merchant_concern_change_tagging"
                  id="merchant_concern_change_tagging"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_change_tagging}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Maya manager issue"
                  value="merchant_concern_maya_manager_issue"
                  id="merchant_concern_maya_manager_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_maya_manager_issue}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchant_concern_other"
                  id="merchant_concern_other"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchant_concern_no_issue}
                />
              </Col>
              {primaryDetails.merchant_concern_other && (
                <Col xs={12}>
                  <Form.Control
                    name="merchant_concern_other_details"
                    type="text"
                    value={primaryDetails.merchant_concern_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.merchant_concern_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No Issue"
                  value="merchant_concern_no_issue"
                  id="merchant_concern_no_issue"
                  name="merchant_concern"
                  type="checkbox"
                  checked={primaryDetails.merchant_concern_no_issue}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.merchant_concern}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="servicing">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Servicing <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Poor connection"
                  value="servicing_poor_connection"
                  id="servicing_poor_connection"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_poor_connection}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Settlement issue"
                  value="servicing_settlement_issue"
                  id="servicing_settlement_issue"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_settlement_issue}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Thermal rolls request"
                  value="servicing_thermal_rolls_request"
                  id="servicing_thermal_rolls_request"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_thermal_rolls_request}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Transaction failed"
                  value="servicing_transaction_failed"
                  id="servicing_transaction_failed"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_transaction_failed}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Reversal transaction"
                  value="servicing_reversal_transaction"
                  id="servicing_reversal_transaction"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_reversal_transaction}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No receipt"
                  value="servicing_no_receipt"
                  id="servicing_no_receipt"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_no_receipt}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Print problems"
                  value="servicing_print_problems"
                  id="servicing_print_problems"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_print_problems}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Terminal replacement"
                  value="servicing_terminal_replacement"
                  id="servicing_terminal_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_terminal_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Charger replacement"
                  value="servicing_charger_replacement"
                  id="servicing_charger_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_charger_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Simcard replacement"
                  value="servicing_simcard_replacement"
                  id="servicing_simcard_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.servicing_simcard_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>

              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="servicing_other"
                  id="servicing_other"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.servicing_no_issue}
                />
              </Col>
              {primaryDetails.servicing_other && (
                <Col xs={12}>
                  <Form.Control
                    name="servicing_other_details"
                    type="text"
                    value={primaryDetails.servicing_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.servicing_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No Issue"
                  value="servicing_no_issue"
                  id="servicing_no_issue"
                  name="servicing"
                  type="checkbox"
                  checked={primaryDetails.servicing_no_issue}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.servicing}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="terminalAvailable">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Replacement <span className="required-badge">* </span>
                </Col>
               
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Terminal replacement"
                  value="replacement_terminal_replacement"
                  id="replacement_terminal_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.replacement_terminal_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.replacement_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Charger replacement"
                  value="replacement_charger_replacement"
                  id="replacement_charger_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.replacement_charger_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.replacement_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Simcard replacement"
                  value="replacement_simcard_replacement"
                  id="replacement_simcard_replacement"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.replacement_simcard_replacement}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.replacement_no_issue}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="No Issue"
                  value="replacement_no_issue"
                  id="replacement_no_issue"
                  name="replacement"
                  type="checkbox"
                  checked={primaryDetails.replacement_no_issue}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.replacement}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="merch_req">
            <Form.Label className="font-med">
              <Row>
                <Col xs={12}>
                  Merchandising Request{" "}
                  <span className="required-badge">* </span>
                </Col>
                {/* <Col xs={12}>
								<span className="font-regular font-10">Multiple answers</span>
							</Col> */}
              </Row>
            </Form.Label>
            <Row>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Standee"
                  value="merchandising_request_standee"
                  id="merchandising_request_standee"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_standee}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Door Hanger"
                  value="merchandising_request_door_hanger"
                  id="merchandising_request_door_hanger"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_door_hanger}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Billfolds"
                  value="merchandising_request_billfolds"
                  id="merchandising_request_billfolds"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_billfolds}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Ballpen"
                  value="merchandising_request_ballpen"
                  id="merchandising_request_ballpen"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_ballpen}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Tarpaulin"
                  value="merchandising_request_tarpaulin"
                  id="merchandising_request_tarpaulin"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_tarpaulin}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Others"
                  value="merchandising_request_other"
                  id="merchandising_request_other"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_other}
                  onClick={handleDetailsChange}
                  disabled={primaryDetails.merchandising_request_none}
                />
              </Col>
              {primaryDetails.merchandising_request_other && (
                <Col xs={12}>
                  <Form.Control
                    name="merchandising_request_other_details"
                    type="text"
                    value={primaryDetails.merchandising_request_other_details}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.merchandising_request_other_details}
                    message={"This Field is Required*."}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Form.Check
                  inline
                  label="None"
                  value="merchandising_request_none"
                  id="merchandising_request_none"
                  name="merchandising_request"
                  type="checkbox"
                  checked={primaryDetails.merchandising_request_none}
                  onClick={handleDetailsChange}
                />
              </Col>
              <InputError
                isValid={isError.merchandising_request}
                message={"This Field is Required*."}
              />
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Retraining Concern <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-retraining_concern-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="retraining_concern"
                    type={type}
                    id={`inline-retraining_concern-${type}-2`}
                    defaultChecked={primaryDetails.retraining_concern === "1"}
                    onClick={handleDetailsChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    value="0"
                    name="retraining_concern"
                    type={type}
                    id={`inline-retraining_concern-${type}-3`}
                    defaultChecked={primaryDetails.retraining_concern === "0"}
                    onClick={handleDetailsChange}
                  />
                </>
                <InputError
                  isValid={isError.retraining_concern}
                  message={"This Field is Required*."}
                />
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrph">
            <Form.Label className="font-med ">
              Done/Resolved <span className="required-badge">*</span>
              {/* <div className="text-justify">
                            <span className="font-regular font-10">Interoperability. Maya app pwede mag scan gamit ang GCash QR. GCash app pwede mag scan gamit ang Maya QR. etc.</span>
                        </div> */}
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-is_resolved-${type}`} className="mb-3">
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    value="1"
                    name="is_resolved"
                    type={type}
                    id={`inline-is_resolved-${type}-2`}
                    defaultChecked={primaryDetails.is_resolved === "1"}
                    onClick={handleDetailsChange}
                  />
                  <Form.Check
                    inline
                    label="Not yet"
                    value="0"
                    name="is_resolved"
                    type={type}
                    id={`inline-is_resolved-${type}-3`}
                    defaultChecked={primaryDetails.is_resolved === "0"}
                    onClick={handleDetailsChange}
                  />
                </>
                <InputError
                  isValid={isError.is_resolved}
                  message={"This Field is Required*."}
                />
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalation">
            <Form.Label className="font-med">
              Ongoing Escalation/Remarks{" "}
              <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="escalation"
              type="text"
              value={primaryDetails.escalation}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.escalation}
              message={"This Field is Required*."}
            />
          </Form.Group>
        
          <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
            <Button
              variant="primary"
              size="md"
              className="full-width-btn confirm-btn"
              onClick={handleSubmit}
            >
              Next
            </Button>
          </Form.Group>
        </Form>
      </Row>
      {/* <img src={swoosh} alt="logo" height={250} className={"swoosh"} /> */}
      <Modal
        show={showMaximum}
        onHide={() => setShowMaximum(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Row style={{ display: "contents" }}>
            <span className="title" style={{ textAlign: "center" }}>
              You can only attach a maximum of 2 images!
            </span>
          </Row>
        </Modal.Header>
        <Modal.Body className="action">
          <Row>
            <Col>
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn logout"
                onClick={() => setShowMaximum(false)}
              >
                CLOSE
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default RegistrationVer2
