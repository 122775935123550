import React, {useState} from "react"
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Pages/Login/Login';
import Location from './Pages/Location/Location';
import './Components/FontAwesome';
import SwitchForm from './Pages/Form/SwitchForm';
import { getTokenExpiry, getUser, refreshPage, removeUserSession } from "./Helpers/Utils/Common";
import { ToastContainer, toast } from "react-toastify";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(getUser());
  const tokenExpiry = getTokenExpiry();

  function promptExpiry() {
    toast.warning("TOKEN HAS EXPIRED. PLEASE LOG IN AGAIN...");
    setTimeout(() => {
      removeUserSession();
      refreshPage();
    },5000);
  }

  React.useEffect(() => {
    var startDate = new Date().getTime();
    var endDate;

    if(tokenExpiry != null) {
      endDate = new Date(tokenExpiry.replace(/['"]+/g, ''));

      var seconds = Math.floor((endDate - startDate)/1000);
      setInterval(promptExpiry, parseFloat(seconds) * 1000);
    } 
  },[]);

  return (
    <div className="App">
     <Router>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/timein" element={<Location/>}/>
          <Route path="/register/add" element={<SwitchForm/>}/>
          {/* <Route path="/register/add/:lat/:lng" element={<SwitchForm/>}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
