import {
  getAddress1,
  getAddress2,
  getBranch,
  getFirstName,
  getKey,
  getLastName,
  getToken,
  getUser,
  getUserName,
  getUserType,
} from "../Utils/Common"
import {
  getAPICall,
  postAPICall,
  putAPICall,
  deleteAPICall,
  API_KEY,
  TOKEN,
  postAPICall2,
  getAPICallGoogle,
} from "./axiosMethodCalls"

//Add Information API
export const createInfo = async (primaryDetails, sqrTerminals) => {
  var full_name = getFirstName() + " " + getLastName()
  try {
    var sqr_terminal = []
    sqrTerminals
      .filter((data) => data.selected === true)
      .map((val) => sqr_terminal.push(val.value))
      
    var formData = new FormData()

    if (primaryDetails.merchant_id !== "Others") {
      formData.append("merchant_id", primaryDetails.merchant_id)
    }
    if (primaryDetails.outlet_id !== "Others") {
      formData.append("outlet_id", primaryDetails.outlet_id)
    }
    formData.append("requester", getUser())
    formData.append("token", getToken().replace(/['"]+/g, ""))
    formData.append("full_name", full_name)
    formData.append("merchant_name", primaryDetails.merchant_name)

    formData.append("outlet", primaryDetails.outlet)
    formData.append("city", primaryDetails.city)
    formData.append("brgy", primaryDetails.brgy)
    formData.append("postal_code", primaryDetails.postal_code)
    formData.append("floor_no", primaryDetails.floor_no)
    formData.append("building", primaryDetails.building)
    formData.append("coordinates", primaryDetails.coordinates)
    formData.append("is_maya_merchant", primaryDetails.maya_merchant)
    formData.append("maya_merchant_sqr", primaryDetails.merchant_mid_sqr)
    formData.append(
      "maya_merchant_terminal",
      primaryDetails.merchant_mid_terminal
    )
    formData.append("terminal_sn", primaryDetails.sn_terminal)
    formData.append("sqr_store_code", primaryDetails.store_code)
    formData.append("name", primaryDetails.name)
    formData.append("number", primaryDetails.number)
    formData.append("email", primaryDetails.email)
    formData.append("qrph_awareness", primaryDetails.qrph_known)
    formData.append("maya_solutions", primaryDetails.maya_solutions)
    formData.append(
      "merchandising_restrictions",
      primaryDetails.merchandising_restrictions
    )
    formData.append("retraining_concern", primaryDetails.retraining_concern)
    formData.append("is_resolved", primaryDetails.is_resolved)
    formData.append("escalation", primaryDetails.escalation)
    formData.append("available_terminal", primaryDetails.available_terminal)
    formData.append("solution_device", primaryDetails.solution_device)
    formData.append("maya_transaction", primaryDetails.maya_transaction)
    formData.append("gcash_transaction", primaryDetails.gcash_transaction)
    formData.append("cash_transaction", primaryDetails.cash_transaction)
    formData.append(
      "other_transaction_details",
      primaryDetails.other_transaction_details
    )
    formData.append("other_transaction", primaryDetails.other_transaction)
    formData.append("own_merchandising", primaryDetails.own_merchandising)
    formData.append("merchant_concern", primaryDetails.merchant_concern)
    formData.append("servicing", primaryDetails.servicing)
    formData.append("replacement", primaryDetails.replacement)
    formData.append(
      "merchandising_request",
      primaryDetails.merchandising_request
    )
    formData.append(
      "available_terminal_other_details",
      primaryDetails.available_terminal_other_details
    )
    formData.append(
      "solution_device_other_details",
      primaryDetails.solution_device_other_details
    )
    formData.append(
      "own_merchandising_other_details",
      primaryDetails.own_merchandising_other_details
    )
    formData.append(
      "merchant_concern_other_details",
      primaryDetails.merchant_concern_other_details
    )
    formData.append(
      "servicing_other_details",
      primaryDetails.servicing_other_details
    )
    formData.append(
      "merchandising_request_other_details",
      primaryDetails.merchandising_request_other_details
    )
    formData.append("sqr_terminals", sqrTerminals.join(","))

    // for (const [key, value] of Object.entries(primaryDetails)) {
    //   if (key === "serial_numbers") {
    //     const serial = value.join(", ");
    //     formData.append("device_serial_numbers", serial);
    //   } else if (key === "store_codes") {
    //     const codes = value.join(", ");
    //     formData.append("sqr_store_codes", codes);
    //   } else if(key === "other_competitor"){
    //     // console.log(value)
    //     Array.from(value).forEach(file => {
    //       formData.append('other_competitor[]', file);
    //     })
    //   } else {
    //     formData.append(key, value);
    //   }
    // }

    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "forms/add",
      formData
    )
    return { data: response.data }
  } catch (error) {
    // console.log(error)
    return { error: error.response }
  }
}

//Add Customer API
export const createCustomer = async (
  id,
  details,
  productCodes,
  freebies,
  promoList
) => {
  try {
    var formData = new FormData()
    formData.append("requester", getUser())
    formData.append("token", getToken().replace(/['"]+/g, ""))
    formData.append("form_id", id)

    for (const [key, value] of Object.entries(details)) {
      formData.append(key, value)
    }

    productCodes.map((data) => {
      formData.append("purchased_products[]", data.qty)
    })

    promoList.map((data) => {
      formData.append("promotions[]", data.name)
      formData.append("promotion_qties[]", data.qty)
    })

    freebies.map((data) => {
      formData.append("freebies[]", data.name)
      formData.append("freebie_qties[]", data.qty)
    })

    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "forms/add_customer",
      formData
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

//Get Store API
export const getLocations = async (
  date_from,
  date_to,
  latitude,
  longitude,
  page
) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "forms/search",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        date_from: date_from,
        date_to: date_to,
        encoded_by: `${getFirstName()} ${getLastName()}`,
        role: getUserType(),
        latitude: latitude,
        longitude: longitude,
        page: page,
      }
    )
    return { data: response.data.data }
  } catch (error) {
    return { error: error.response }
  }
}
export const getAccounts = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "accounts/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    )
    return { data: response.data.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getPromos = async (activation_type, classification_type) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "promos/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        classification_type: classification_type,
        activation_type: activation_type,
      }
    )
    return { data: response.data.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getFreebies = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "freebies/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    )
    return { data: response.data.data }
  } catch (error) {
    return { error: error.response }
  }
}

// GOOGLE API
export const getLocationOnLoad = async (lat, lng) => {
  try {
    const response = await getAPICallGoogle(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyAHeix3Mf1YRppXp1hxArkPwkevOZFc1Ew"
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getDropdownData = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "forms/get_form_dropdown_data",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getAllMerchants = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "merchants/get_all",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getMerchant = async (id) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "merchants/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        merchant_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getAllOutlets = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "outlets/get_all",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
