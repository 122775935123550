import { getToken, getUser } from "../Utils/Common";
import { postAPICall, postAPICall2 } from "./axiosMethodCalls";
import Moment from "moment";

export const createTimesheet = async (data) => {
    try {
      var formData = new FormData();
      formData.append("requester", getUser())
      formData.append("token", getToken().replace(/['"]+/g, ''))
      // formData.append("address",data.address_1)
      // formData.append("address2",data.address_2)
      // formData.append("latitude",data.latitude)
      // formData.append("longitude",data.longitude)
      formData.append("photo_proof",data.photo_proof)
      // formData.append("photo_proof",data.photo_proof[0])
      const response = await postAPICall2( process.env.REACT_APP_LINK + 'timesheets/time_in', formData);
        return ({data:response.data}); 
    } catch (error) {
        return ({error: error.response});
    }
}

export const createTimesheetOut = async (timesheet_id) => {
    try {
      var formData = new FormData();
      formData.append("requester", getUser())
      formData.append("token", getToken().replace(/['"]+/g, ''))
      formData.append("timesheet_id",timesheet_id)
      const response = await postAPICall2( process.env.REACT_APP_LINK + 'timesheets/time_out', formData);
        return ({data:response.data}); 
    } catch (error) {
        return ({error: error.response});
    }
}

export const searchTimesheet = async () => {
    try {
      var formData = new FormData();
      formData.append("requester", getUser())
      formData.append("token", getToken().replace(/['"]+/g, ''))
    //   formData.append("timesheet_id",timesheet_id)
      const response = await postAPICall2( process.env.REACT_APP_LINK + 'timesheets/search', {
        requester:getUser(),
        token: getToken().replace(/['"]+/g, ''),
        date_from: Moment().format("YYYY-MM-DD"),
        date_to: Moment().format("YYYY-MM-DD"),
        // date_from: new Date().toLocaleDateString("en-US"),
        // date_to: new Date().toLocaleDateString("en-US"),
        type:"agent",
        agent_id:getUser()
      });
        return ({data:response.data}); 
    } catch (error) {
        return ({error: error.response});
    }
}

export const searchFormResponses = async () => {
  try {
    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "forms/search",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ''),
        date_from: Moment().format("YYYY-MM-DD"),
        date_to: Moment().format("YYYY-MM-DD"),
        requester_only: 1,
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};
